import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { zendeskLinks } from '../../zendesk'

const FaqLink = styled.a`
  align-items: center;
  background-color: ${props => props.theme.colors['yellow-100']};
  border-radius: 1.5rem;
  color: ${props => props.theme.colors.darkergrey};
  display: flex;
  font-family: ${props => props.theme.fonts.serif};
  font-size: 1rem;
  font-weight: bold;
  height: 3rem;
  justify-content: center;
  min-width: 4.5rem;
  text-decoration: none;
  padding: 0 0.5rem;
`

const ZendeskWidget = ({ className }) => {
  return (
    <FaqLink
      className={className}
      href={zendeskLinks().home}
      data-testid="zendesk-widget"
    >
      FAQ
    </FaqLink>
  )
}

ZendeskWidget.propTypes = {
  className: PropTypes.string,
}

ZendeskWidget.defaultProps = {
  className: null,
}

export default ZendeskWidget
