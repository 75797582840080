import React from 'react'
import * as Yup from 'yup'
import { useTranslation, Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Input, Button } from '@boxine/tonies-ui'
import useHandleForm from '../../hooks/useHandleForm'
import fillMessageObject from '../../helper/fillMessageObject'
import useDisabledSubmitButton from '../../hooks/useDisabledSubmitButton'
import { emailRegex } from '../../helper/variables'

const StyledForm = styled.form`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  max-width: 17.5rem;
  width: 100%;
`

const StyledLoginButton = styled(Button)`
  justify-self: center;
`

const ErrorMessageLink = styled.a`
  color: inherit;
`

const PasswordResetForm = ({
  actionUrl,
  className,
  serverErrors,
  userEmail,
  registerUrl,
}) => {
  const { t } = useTranslation()

  const formErrorTranslations = {
    username: (
      <Trans i18nKey="passwordResetForm:serverErrors.username">
        <span>
          Diese E-Mail-Adresse ist keinem Tonie-Konto zugeordnet. Möchtest du
          dich
          <ErrorMessageLink href={registerUrl}>registrieren?</ErrorMessageLink>
        </span>
      </Trans>
    ),
  }

  const findFormErrorMessage = key =>
    serverErrors[key] && formErrorTranslations[key]

  const {
    onChange,
    formRef,
    handleSubmit,
    handleBlur,
    values,
    touched,
    errors,
  } = useHandleForm(
    {
      username: Yup.string()
        .matches(emailRegex, t('passwordResetForm:errors.usernameNotValid'))
        .required(t('passwordResetForm:errors.required')),
    },
    {
      initialValues: {
        username: userEmail || '',
      },
    }
  )

  const isSubmitButtonDisabled = useDisabledSubmitButton(errors)

  return (
    <StyledForm
      action={actionUrl}
      className={className}
      method="POST"
      noValidate
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <Input
        dataTestId="username"
        autoComplete="username"
        isFullWidth
        hasHiddenLabel
        isRequired
        label={t('passwordResetForm:username')}
        message={fillMessageObject(
          'username',
          serverErrors.tryAgainLater
            ? t('passwordResetForm:serverErrors.tryAgainLater')
            : findFormErrorMessage('username'),
          touched,
          errors
        )}
        name="username"
        onBlur={handleBlur}
        onChange={onChange}
        placeholder={t('passwordResetForm:username')}
        type="username"
        value={values.username}
      />
      <StyledLoginButton
        data-testid="password-reset-submit"
        disabled={isSubmitButtonDisabled}
        type="submit"
      >
        {t('passwordResetForm:submitButton')}
      </StyledLoginButton>
    </StyledForm>
  )
}

PasswordResetForm.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  client: PropTypes.string.isRequired,
  errorStatus: PropTypes.string.isRequired,
  hiddenFormValueStateChecker: PropTypes.string.isRequired,
  registerUrl: PropTypes.string.isRequired,
  serverErrors: PropTypes.object,
  userEmail: PropTypes.string,
}

PasswordResetForm.defaultProps = {
  className: null,
  serverErrors: null,
  userEmail: null,
}

export default PasswordResetForm
