import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Headline3 } from '@boxine/tonies-ui'
import { Text } from '../Typography'

const StyledWrapper = styled.div`
  max-width: 30rem;
`
const StyledHeadline = styled(Headline3)`
  margin-bottom: 0.8rem;
  text-align: center;
  font-weight: normal;
`

const StyledSubheadline = styled(Text)`
  margin: 0 auto 1.75rem;
  text-align: center;
`

const SectionTitle = ({
  className,
  headlineContent,
  subheadlineContent,
  headlineTag,
  dataTestId,
}) => (
  <StyledWrapper className={className}>
    <StyledHeadline data-testid={dataTestId} asHTMLTag={headlineTag}>
      {headlineContent}
    </StyledHeadline>
    <StyledSubheadline data-testid={`${dataTestId}-subheadline`}>
      {subheadlineContent}
    </StyledSubheadline>
  </StyledWrapper>
)

SectionTitle.propTypes = {
  className: PropTypes.string,
  headlineContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
    .isRequired,
  headlineTag: PropTypes.string,
  subheadlineContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
    .isRequired,
  dataTestId: PropTypes.string,
}

SectionTitle.defaultProps = {
  className: null,
  dataTestId: 'section-title',
  headlineTag: 'h1',
}

export default SectionTitle
