import React from 'react'
import styled from 'styled-components'
import { Headline1, useEnvLink } from '@boxine/tonies-ui'
import { Trans, useTranslation } from 'react-i18next'
import { BelloAccent, Text } from '../../components/Typography'
import TeaserBox from '../../components/TeaserBox'
import FullScreenTheme from '../../themes/FullScreenTheme'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useRegion } from '../../hooks/useRegion'

const TeaserBoxWrapper = styled.div`
  display: grid;
  grid-gap: 4rem;
  justify-items: center;

  @media screen and (min-width: 580px) {
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }
`

const StyledText = styled(Text)`
  font-size: 1.5rem;
  margin: 0 0 2rem;
`

const StyledHeadline = styled(Headline1)`
  margin-bottom: 0.8rem;
  text-align: center;
`

const ChooseSite = () => {
  const { t } = useTranslation()
  const { region } = useRegion()

  useDocumentTitle(t('chooseSite:PageTitle'))

  const shopLink = useEnvLink(
    'shopLink',
    process.env.REACT_APP_ENVIRONMENT,
    region
  )

  const cloudLink = useEnvLink(
    'cloudLink',
    process.env.REACT_APP_ENVIRONMENT,
    region
  )

  return (
    <FullScreenTheme
      imgStyle={{
        maxWidth: '13rem',
      }}
      translationNamespace="chooseSite"
    >
      <StyledHeadline>
        <Trans i18nKey="chooseSite:headline">
          <BelloAccent>Willkommen</BelloAccent> zurück!
        </Trans>
      </StyledHeadline>
      <StyledText data-testid="subheadline">
        {t('chooseSite:subheadline')}
      </StyledText>
      <TeaserBoxWrapper>
        <TeaserBox id="shop" link={shopLink} />
        <TeaserBox id="cloud" link={cloudLink} />
      </TeaserBoxWrapper>
    </FullScreenTheme>
  )
}

export default ChooseSite
