import React from 'react'
import * as Yup from 'yup'
import { useTranslation, Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Input, media, CountrySelect } from '@boxine/tonies-ui'
import useHandleForm from '../../hooks/useHandleForm'
import useDisabledSubmitButton from '../../hooks/useDisabledSubmitButton'
import fillMessageObject from '../../helper/fillMessageObject'
import { Strong, Text } from '../Typography'
import { useRegion } from '../../hooks/useRegion'

const TextAlignCenter = styled(Text)`
  text-align: center;
`

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: minmax(0, 100%);
  grid-gap: 1rem;
  margin: 0 auto;
`

const StyledFormRow = styled.div`
  ${media.tablet`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    justify-self: center;
    max-width: 30rem;
    min-width: 20rem;
  `}
`

const StyledSubmitButton = styled(Button)`
  justify-self: center;
  margin-bottom: 1rem;
`

const StyledInput = styled(Input)`
  margin-bottom: 1rem;
`

const initialValues = {
  firstName: '',
  lastName: '',
  country: '',
}

const emptyOrNotAnEmail = value => !value?.includes('@')

const CompleteProfileForm = ({ actionUrl, className }) => {
  const { t } = useTranslation()
  const { region } = useRegion()
  const {
    onChange,
    formRef,
    handleSubmit,
    handleBlur,
    values,
    touched,
    errors,
  } = useHandleForm(
    {
      firstName: Yup.string().test(
        'no-at-symbol-1',
        t('completeProfileForm:errors.checkFirstName'),
        emptyOrNotAnEmail
      ),
      lastName: Yup.string().test(
        'no-at-symbol-2',
        t('completeProfileForm:errors.checkLastName'),
        emptyOrNotAnEmail
      ),
      country: Yup.string(),
    },
    { initialValues }
  )

  const isSubmitButtonDisabled = useDisabledSubmitButton(errors)

  return (
    <StyledForm
      action={actionUrl}
      className={className}
      method="POST"
      noValidate
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <TextAlignCenter>
        <Trans
          i18nKey="completeProfileForm:countryText"
          components={{ strong: <Strong /> }}
        />
      </TextAlignCenter>
      <StyledFormRow>
        <CountrySelect
          name="country"
          isRequired
          onChangeFunc={onChange}
          value={values.country}
          defaultValue={region}
        />
      </StyledFormRow>

      <TextAlignCenter>{t('completeProfileForm:nameText')}</TextAlignCenter>
      <StyledFormRow>
        <StyledInput
          dataTestId="first-name"
          isFullWidth
          label={t('completeProfileForm:firstName')}
          name="firstName"
          onBlur={handleBlur}
          onChange={onChange}
          placeholder={t('completeProfileForm:firstName')}
          value={values.firstName}
          message={fillMessageObject('firstName', null, touched, errors)}
        />
        <StyledInput
          dataTestId="last-name"
          isFullWidth
          label={t('completeProfileForm:lastName')}
          name="lastName"
          onBlur={handleBlur}
          onChange={onChange}
          placeholder={t('completeProfileForm:lastName')}
          value={values.lastName}
          message={fillMessageObject('lastName', null, touched, errors)}
        />
      </StyledFormRow>
      <StyledSubmitButton
        data-testid="complete-profile-submit"
        type="submit"
        disabled={isSubmitButtonDisabled}
      >
        {t('completeProfileForm:submitButton')}
      </StyledSubmitButton>
    </StyledForm>
  )
}

CompleteProfileForm.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
}

CompleteProfileForm.defaultProps = {
  className: null,
}

export default CompleteProfileForm
