import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { theme, useMedia } from '@boxine/tonies-ui'
import { Trans, useTranslation } from 'react-i18next'
import LoginForm from '../../components/LoginForm'
import SectionTitle from '../../components/SectionTitle'
import TeaserTheme from '../../themes/TeaserTheme'
import BackLink from '../../components/BackLink'
import getImagePath from '../../helper/getImagePath'
import AppContext from '../../contexts/AppContext'
import { BelloAccent } from '../../components/Typography'

const StyledForm = styled(LoginForm)`
  align-items: baseline;
  margin: 0 auto 2rem;
`

const StyledImage = styled.img`
  max-width: 10rem;

  @media screen and (max-width: ${props => props.theme.screenTablet}px) {
    justify-self: center;
  }
`

const InvalidLinkLogin = ({
  actionUrl,
  backUrl,
  client,
  errorStatus,
  userEmail,
}) => {
  const { isMinimalVersion } = useContext(AppContext)
  const { t } = useTranslation()
  const isTablet = useMedia({ minWidth: theme.screenTablet })

  return (
    <TeaserTheme
      headerLeft={
        <>
          {!isMinimalVersion && <BackLink href={backUrl} client={client} />}
          {isTablet && (
            <StyledImage
              alt=""
              src={getImagePath(t('invalidLinkLogin:imgPath'))}
            />
          )}
          <SectionTitle
            headlineContent={
              <Trans i18nKey="invalidLinkLogin:headline">
                <BelloAccent>Uups,</BelloAccent> dein Bestätigungslink ist
                abgelaufen!
              </Trans>
            }
            subheadlineContent={t('invalidLinkLogin:subheadline')}
          />
        </>
      }
      contentLeft={
        <StyledForm
          page="invalid-link"
          actionUrl={actionUrl}
          errorStatus={errorStatus}
          userEmail={userEmail}
        />
      }
    />
  )
}

InvalidLinkLogin.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  backUrl: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  errorStatus: PropTypes.string,
  userEmail: PropTypes.string,
}

InvalidLinkLogin.defaultProps = {
  errorStatus: null,
  userEmail: null,
}

export default InvalidLinkLogin
