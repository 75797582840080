import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import NewPasswordForm from '../../components/NewPasswordForm'
import SectionTitle from '../../components/SectionTitle'
import FullScreenTheme from '../../themes/FullScreenTheme'
import { BelloAccent, Strong } from '../../components/Typography'

const StyledForm = styled(NewPasswordForm)`
  display: grid;
  margin-bottom: 2rem;
`

const PasswordPolicy = ({ actionUrl, backUrl, errorStatus }) => {
  return (
    <FullScreenTheme translationNamespace="passwordPolicy">
      <SectionTitle
        headlineContent={
          <Trans i18nKey="passwordPolicy:headline">
            Wir haben die Passwort-Richtlinien
            <BelloAccent>angegelichen</BelloAccent>!
          </Trans>
        }
        subheadlineContent={
          <Trans i18nKey="passwordPolicy:subheadline">
            <span>
              Für <Strong>my</Strong>tonies und den Onlineshop gelten ab sofort
              einheitliche Passwort-Richtlinien. Das heißt: Dein Passwort muss
              mindestens acht Zeichen lang sein. Bitte trage dein neues Passwort
              hier ein und klicke auf „Neues Passwort speichern“:
            </span>
          </Trans>
        }
      />
      <StyledForm
        actionUrl={actionUrl}
        backUrl={backUrl}
        errorStatus={errorStatus}
      />
    </FullScreenTheme>
  )
}

PasswordPolicy.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  backUrl: PropTypes.string.isRequired,
  errorStatus: PropTypes.string,
}

PasswordPolicy.defaultProps = {
  errorStatus: null,
}

export default PasswordPolicy
