import React from 'react'
import { RegionContext } from '../providers/RegionProvider'

export const useRegion = () => {
  const context = React.useContext(RegionContext)

  if (!context) {
    throw new Error(
      'Please add the `RegionProvider` in order to use `useRegion`'
    )
  }

  return context
}
