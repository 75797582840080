import React from 'react'
import { PropTypes } from 'prop-types'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { Headline1, Headline3, Button, Spacing } from '@boxine/tonies-ui'
import { ConditionalStageWarning } from '../../components/ConditionalStageWarning'
import getImagePath from '../../helper/getImagePath'
import { BelloAccent, Text as TextComponent } from '../../components/Typography'

const Text = styled(TextComponent)`
  margin: 0.5rem 0;
`

const BackgroundWave = css`
  &::after {
    bottom: -8px;
    content: url(${getImagePath('background-wave.svg')});
    position: absolute;
    width: 100%;
    z-index: 0;
  }
`

const Site = styled.div`
  background-color: ${props => props.theme.White};
  height: 100vh;
`

const Wrapper = styled.div`
  @media screen and (min-width: ${props => props.theme.screenTablet}px) {
    display: grid;
    justify-content: center;
    position: relative;
    background-color: ${props => props.theme.application.backgroundColor};

    ${BackgroundWave}
  }
`

const InnerWrapper = styled.div`
  display: grid;
  grid-gap: 2rem;

  @media screen and (min-width: ${props => props.theme.screenTablet}px) {
    grid-template-columns: 70% 30%;
    max-width: 60rem;
  }
`

const Content = styled.div`
  align-self: center;
  display: grid;
  grid-gap: 0.5rem;
  justify-items: baseline;
  padding: 2rem 2rem 7vw;
  z-index: 1;

  @media screen and (max-width: ${props => props.theme.screenTablet}px) {
    background-color: ${props => props.theme.application.backgroundColor};
    padding-bottom: 14vw;
    position: relative;

    ${BackgroundWave}
  }
`

const StyledImage = styled.img`
  align-self: flex-end;
  height: 25rem;
  justify-self: center;
  max-height: 25rem;
  max-width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 1;

  @media screen and (min-width: ${props => props.theme.screenTablet}px) {
    height: 150%;
    margin-bottom: -30%;
  }
`

const ErrorPage = ({ errorCode, backUrl }) => {
  const { t } = useTranslation()
  const supportedTranslationsForErrorCodes = ['404', '500']
  const isErrorCodeSupported =
    supportedTranslationsForErrorCodes.includes(errorCode)
  const getValidErrorCode = isErrorCodeSupported ? errorCode : '500'

  return (
    <Site>
      <ConditionalStageWarning />

      <Wrapper>
        <InnerWrapper>
          <Content>
            <Spacing mb="spacing-s">
              <Headline1 data-testid="errorpage-headline">
                <Trans i18nKey={`errorPage:${getValidErrorCode}.headline`}>
                  <BelloAccent>Au Backe!</BelloAccent>
                </Trans>
              </Headline1>
            </Spacing>
            <Headline3
              weight={500}
              data-testid="errorpage-subheadline"
              asHTMLTag="h2"
            >
              <Trans i18nKey="errorPage:subheadline">
                Fehlercode {{ errorCode }}
              </Trans>
            </Headline3>
            <Text>{t(`errorPage:${getValidErrorCode}.text`)}</Text>
            <Button
              variant="secondary"
              as="a"
              data-testid="errorpage-link"
              styling="text"
              href={backUrl || t(`errorPage:link`)}
            >
              {t(`errorPage:linkText`)}
            </Button>
          </Content>

          <StyledImage alt={t(`errorPage:${getValidErrorCode}.imgAlt`)} />
        </InnerWrapper>
      </Wrapper>
    </Site>
  )
}

ErrorPage.propTypes = {
  errorCode: PropTypes.string,
  backUrl: PropTypes.string,
}

ErrorPage.defaultProps = {
  errorCode: '404',
  backUrl: null,
}

export default ErrorPage
