import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { variables, Button, theme, useMedia } from '@boxine/tonies-ui'
import { Trans, useTranslation } from 'react-i18next'
import SectionTitle from '../../components/SectionTitle'
import TeaserTheme from '../../themes/TeaserTheme'
import BackLink from '../../components/BackLink'
import RegisterForm from '../../components/RegisterForm'
import getImagePath from '../../helper/getImagePath'
import AppContext from '../../contexts/AppContext'
import useStartPollingLocalStorage from '../../hooks/useStartPollingLocalStorage'
import { BelloAccent, Text } from '../../components/Typography'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

const { BrandPrimary } = variables

const StyledForm = styled(RegisterForm)`
  align-items: baseline;
  margin: 0 auto 2rem;
`

const StyledLink = styled(Button)`
  color: ${BrandPrimary};
  text-decoration: underline;
`

const StyledImage = styled.img`
  max-width: 10rem;

  @media screen and (max-width: ${props => props.theme.screenTablet}px) {
    justify-self: center;
  }
`

const Register = ({
  actionUrl,
  backUrl,
  client,
  errorStatus,
  loginUrl,
  resetPasswordUrl,
  serverErrors,
  userEmail,
}) => {
  const { isMinimalVersion } = useContext(AppContext)
  const { t } = useTranslation()
  const isTablet = useMedia({ minWidth: theme.screenTablet })

  useStartPollingLocalStorage(backUrl)

  useDocumentTitle(t('register:PageTitle'))

  return (
    <TeaserTheme
      translationNamespace="register"
      headerLeft={
        <>
          {!isMinimalVersion && <BackLink href={backUrl} client={client} />}
          {isTablet && (
            <StyledImage alt="" src={getImagePath(t('register:imgPath'))} />
          )}
          <SectionTitle
            headlineContent={
              <Trans i18nKey="register:headline">
                <span>
                  Erstelle dein Tonie-<BelloAccent>Konto!</BelloAccent>
                </span>
              </Trans>
            }
          />
        </>
      }
      contentLeft={
        <>
          <StyledForm
            actionUrl={actionUrl}
            errorStatus={errorStatus}
            loginUrl={loginUrl}
            resetPasswordUrl={resetPasswordUrl}
            serverErrors={serverErrors}
            userEmail={userEmail}
          />

          <Text>{t('register:loginText')}</Text>
          <StyledLink
            variant="secondary"
            as="a"
            data-testid="login-instead-of-register"
            href={loginUrl}
          >
            {t('register:loginButton')}
          </StyledLink>
        </>
      }
    />
  )
}

Register.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  backUrl: PropTypes.string.isRequired,
  loginUrl: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  errorStatus: PropTypes.string,
  resetPasswordUrl: PropTypes.string.isRequired,
  serverErrors: PropTypes.string,
  userEmail: PropTypes.string,
}

Register.defaultProps = {
  errorStatus: null,
  serverErrors: {},
  userEmail: null,
}

export default Register
