import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import EmailChangeForm from '../../components/EmailChangeForm'
import SectionTitle from '../../components/SectionTitle'
import FullScreenTheme from '../../themes/FullScreenTheme'
import AppContext from '../../contexts/AppContext'
import { BelloAccent } from '../../components/Typography'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

const StyledForm = styled(EmailChangeForm)`
  margin-bottom: 2rem;
`

const EmailChange = ({
  actionUrl,
  backUrl,
  client,
  errorStatus,
  hiddenFormValueStateChecker,
  userEmail,
  userFirstName,
  userLastName,
}) => {
  const { isMinimalVersion } = useContext(AppContext)
  const { t } = useTranslation()

  useDocumentTitle(t('emailChange:PageTitle'))

  return (
    <FullScreenTheme
      backUrl={backUrl}
      client={client}
      hasBackLink={!isMinimalVersion}
      translationNamespace="emailChange"
    >
      <SectionTitle
        headlineContent={
          <Trans i18nKey="emailChange:headline">
            <>
              Du möchtest deine E-Mail-Adresse
              <BelloAccent>ändern?</BelloAccent>
            </>
          </Trans>
        }
        subheadlineContent={
          <Trans i18nKey="emailChange:subheadline">
            Bitte trage hier die aktuelle und die neue E-Mail-Adresse ein und
            klicke auf „Jetzt ändern“:
          </Trans>
        }
      />
      <StyledForm
        actionUrl={actionUrl}
        backUrl={backUrl}
        client={client}
        errorStatus={errorStatus}
        hiddenFormValueStateChecker={hiddenFormValueStateChecker}
        userEmail={userEmail}
        userFirstName={userFirstName}
        userLastName={userLastName}
      />
    </FullScreenTheme>
  )
}

EmailChange.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  backUrl: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  errorStatus: PropTypes.string,
  hiddenFormValueStateChecker: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
}

EmailChange.defaultProps = {
  errorStatus: null,
  userFirstName: null,
  userLastName: null,
}

export default EmailChange
