import { useEffect, useRef } from 'react'

const useStartPollingLocalStorage = backUrl => {
  const intervalRef = useRef(null)

  useEffect(() => {
    // to detect if the form was submitted and a possible session has been started
    window.addEventListener('storage', event => {
      if (event.key === 'startPolling') {
        intervalRef.current = setInterval(() => {
          if (event.key === 'startPolling') {
            window.location = backUrl
          }
        }, [5000])
      }
    })

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [backUrl])
}

export default useStartPollingLocalStorage
