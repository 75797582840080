import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'

const useVerifyEmailStatus = (emailVerificationStatusUrl, verifyUrl) => {
  const [isEmailVerified, setIsEmailVerified] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      fetch(emailVerificationStatusUrl)
        .then(response => {
          // The server sends a 400 response if the token expires, typically after 24 hours.
          // This happens frequently when tabs are reactivated in suspended browser instances on mobile devices.
          if (response.status === 400) {
            clearInterval(interval)
          } else {
            response
              .json()
              .then(data => setIsEmailVerified(data.verified === 'true'))
          }
        })
        .catch(error => {
          Sentry.withScope(scope => {
            scope.setFingerprint(['useVerifyEmailStatus'])
            scope.setExtra('component', 'useVerifyEmailStatus')

            Sentry.captureException(error)
          })
        })
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [emailVerificationStatusUrl])

  // redirects to keycloak verify url and shows the next required step
  const redirectToNextRequiredStep = () => {
    if (isEmailVerified) window.location = verifyUrl
  }

  return { isEmailVerified, redirectToNextRequiredStep }
}

export default useVerifyEmailStatus
