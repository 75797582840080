import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import styled from 'styled-components'
import { InputPassword, Button } from '@boxine/tonies-ui'
import useHandleForm from '../../hooks/useHandleForm'
import fillMessageObject from '../../helper/fillMessageObject'
import useDisabledSubmitButton from '../../hooks/useDisabledSubmitButton'

const StyledForm = styled.form`
  display: grid;
  margin-bottom: 1rem;
  max-width: 17.5rem;
  width: 100%;
`

const StyledLoginButton = styled(Button)`
  justify-self: center;
`

const StyledInput = styled(InputPassword)`
  margin-bottom: 0.15rem;
`

const StyledText = styled.p`
  color: ${props => props.theme.Gray};
  font-size: 0.7rem;
  margin-bottom: 1.5rem;
`

const NewPasswordForm = ({ actionUrl, className, errorStatus }) => {
  const { t } = useTranslation()
  const errorMessage = useMemo(
    () =>
      errorStatus === 'invalid_password' &&
      t(`loginForm:errors.invalidPassword`),
    [errorStatus, t]
  )

  const {
    errors,
    formRef,
    handleBlur,
    handleSubmit,
    onChange,
    touched,
    values,
  } = useHandleForm(
    {
      'password-new': Yup.string()
        .required(t('newPasswordForm:errors.passwordRequired'))
        .min(8, t('newPasswordForm:errors.passwordTooShort')),
    },
    {
      initialValues: {
        'password-new': '',
      },
    },
    errorMessage
  )

  const isSubmitButtonDisabled = useDisabledSubmitButton(errors)

  return (
    <StyledForm
      action={actionUrl}
      className={className}
      method="POST"
      noValidate
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <input
        name="password-confirm"
        type="hidden"
        value={values['password-new']}
      />
      <StyledInput
        autoComplete="new-password"
        dataTestId="password-new"
        isFullWidth
        isRequired
        label={t('newPasswordForm:passwordNew')}
        message={fillMessageObject(
          'password-new',
          errorMessage,
          touched,
          errors
        )}
        name="password-new"
        onBlur={handleBlur}
        onChange={onChange}
        placeholder={t('newPasswordForm:passwordNew')}
        value={values['password-new']}
      />
      <StyledText>{t('newPasswordForm:info')}</StyledText>
      <StyledLoginButton
        data-testid="password-policy-submit"
        disabled={isSubmitButtonDisabled}
        type="submit"
      >
        {t('newPasswordForm:submitButton')}
      </StyledLoginButton>
    </StyledForm>
  )
}

NewPasswordForm.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  errorStatus: PropTypes.string.isRequired,
}

NewPasswordForm.defaultProps = {
  className: null,
}

export default NewPasswordForm
