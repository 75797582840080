import { createI18nConfig } from '@boxine/tonies-ui'
import de from './translations/de.json'
import en from './translations/en.json'
import enUS from './translations/en-us.json'
import fr from './translations/fr.json'

const cookieDomain = () => {
  const fullDomain = process.env.REACT_APP_LOGIN_URL || 'login.tonies.com'

  return fullDomain.substring(fullDomain.indexOf('.tonie') + 1)
}

export default createI18nConfig(
  {
    de,
    en,
    'en-US': enUS,
    fr,
  },
  null,
  null,
  false,
  {
    // Configuration for LanguageDetector
    detection: {
      order: ['querystring', 'cookie', 'navigator'],

      // Keys/Params to lookup language from
      lookupQuerystring: 'lang',
      lookupCookie: 'lang',

      // Where to cache
      caches: ['cookie'],

      cookieDomain: cookieDomain(),

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: {
        path: '/',
        sameSite: 'none',
        secure: true,
        httpOnly: false,
      },
    },
  }
)
