import {
  LanguageSwitch,
  Logo,
  MainNavigation,
  OpenLanguageSwitchButton,
  media,
  useIsMobileNavigation,
} from '@boxine/tonies-ui'
import { useId } from '@reach/auto-id'
import { PropTypes } from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import ZendeskWidget from '../components/ZendeskWidget'
import AppContext from '../contexts/AppContext'
import useCookie from '../hooks/useCookie'
import { useSupportedLanguages } from '../hooks/useSupportedLanguages'

const Site = styled.div`
  ${props =>
    !props.isLanguageSwitchOpen &&
    css`
      background: white;
    `};
  display: grid;
  grid-template-rows: ${props =>
    props.isMinimalVersion ? 'auto' : 'auto 1fr'};
  min-height: 100vh;
`

const StyledMainNavigation = styled(MainNavigation)`
  /* header hasn't a max-width on SSO Sites */
  > div {
    max-width: none;

    ${media.tablet`
      img {
        margin-left: 2rem;
      }
    `}
  }
`

const StyledZendeskWidget = styled(ZendeskWidget)`
  bottom: 0.75rem;
  position: fixed;
  right: 0.75rem;
`

const logoPath = process.env.PUBLIC_URL + '/images/toniesLogo.svg'

const SiteTheme = ({ children, showLanguageSwitch }) => {
  const { isMinimalVersion } = useContext(AppContext)
  const { i18n, t } = useTranslation()
  const isMobile = useIsMobileNavigation()
  const supportedLanguages = useSupportedLanguages()
  const [isLanguageSwitchOpen, setIsLanguageSwitchOpen] = useState(false)
  const [wasTriggeredByKeyboard, setWasTriggeredByKeyboard] = useState(false)
  // When the user isn't logged in they can still change the language.
  // This flag will be set when the user does so in a logged out state. Once
  // they sign in they'll expect the language to remain the same, despite there
  // being a potentially different language defined in the user's profile. In
  // that case the value in the profile will be overwritten.
  const [userDecidedLanguage, setUserDecidedLanguage] = useCookie(
    'userDecidedLanguage',
    'false',
    {
      domain:
        process.env.REACT_APP_ENVIRONMENT === 'prod'
          ? 'tonies.com'
          : 'tonie.cloud',
      expires: 7,
      path: '/',
    }
  )
  const idLanguageSwitch = useId()

  // Harmonize languages (de === de-DE, ...)
  const currentLanguageCode = i18n.language === 'de-DE' ? 'de' : i18n.language
  const currentLanguageObject = supportedLanguages.find(
    language => language.languageCode === currentLanguageCode
  )
  const currentLanguageText = currentLanguageObject
    ? currentLanguageObject.text
    : t('language-en')

  // The `kc_locale` query parameter is used to transport the desired language between Keycloak and frontend.
  // Frontend on the other hand relies on the LocalStorage value of `lang` (handled by react-i18next).
  // When `kc_locale` parameter is present in the URL, we use it to sync the value of `lang` in LocalStorage
  // with the value of `kc_locale`.
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)

    if (
      queryParameters.has('kc_locale') &&
      i18n.language !== queryParameters.get('kc_locale')
    ) {
      i18n.changeLanguage(queryParameters.get('kc_locale'))
    }
  }, [i18n])

  function changeLanguage(newLanguageCode) {
    // User made decision --> Override assumed language derived from browser settings
    if (userDecidedLanguage !== 'true') setUserDecidedLanguage('true')

    if (newLanguageCode !== i18n.language) {
      i18n.changeLanguage(newLanguageCode)
      const url = new URL(window.location.href)
      const localeParams = ['ui_locales', 'kc_locale']

      localeParams.forEach(param => {
        if (url.searchParams.has(param)) {
          url.searchParams.set(param, newLanguageCode)
        }
      })

      window.location.replace(url)
    }

    setIsLanguageSwitchOpen(false)
  }

  return (
    <>
      <Site
        isMinimalVersion={isMinimalVersion}
        isLanguageSwitchOpen={isLanguageSwitchOpen}
      >
        {!isMinimalVersion && (
          <StyledMainNavigation
            hasHamburgerMenu={false}
            isLogoAnimated={false}
            logo={
              <Logo
                alt=""
                src={logoPath}
                ratio={[104, 26]}
                responsive={[73, 100]}
                width={isMobile ? '6.5rem' : '10.75rem'}
              />
            }
            mobile={
              showLanguageSwitch && (
                <OpenLanguageSwitchButton
                  dataTestId="main-navigation-current-language"
                  idLanguageSwitch={idLanguageSwitch}
                  isOpen={isLanguageSwitchOpen}
                  onClick={wasTriggeredByKeyboard => {
                    setIsLanguageSwitchOpen(!isLanguageSwitchOpen)
                    setWasTriggeredByKeyboard(wasTriggeredByKeyboard)
                  }}
                  currentLanguage={currentLanguageText}
                />
              )
            }
            secondary={
              showLanguageSwitch && (
                <OpenLanguageSwitchButton
                  dataTestId="main-navigation-current-language"
                  idLanguageSwitch={idLanguageSwitch}
                  isOpen={isLanguageSwitchOpen}
                  onClick={wasTriggeredByKeyboard => {
                    setIsLanguageSwitchOpen(!isLanguageSwitchOpen)
                    setWasTriggeredByKeyboard(wasTriggeredByKeyboard)
                  }}
                  currentLanguage={currentLanguageText}
                />
              )
            }
          />
        )}

        {isLanguageSwitchOpen ? (
          <LanguageSwitch
            availableLanguages={supportedLanguages}
            id={idLanguageSwitch}
            onClose={() => setIsLanguageSwitchOpen(false)}
            onSelectLanguage={changeLanguage}
            shouldFocusFirstElement={wasTriggeredByKeyboard}
          />
        ) : (
          <main>{children}</main>
        )}
      </Site>

      <StyledZendeskWidget />
    </>
  )
}

SiteTheme.propTypes = {
  children: PropTypes.node.isRequired,
  showLanguageSwitch: PropTypes.bool,
}

SiteTheme.defaultProps = {
  showLanguageSwitch: true,
}

export default SiteTheme
