const fillMessageObject = (key, serverErrorMessage, touched, errors) => {
  let errorObject:
    | { type: 'error'; message: { text: string } | any }
    | undefined = undefined

  // return an ErrorMessage Object with the serverErrorMessage, when input isn't touched
  if (serverErrorMessage && !touched[key]) {
    errorObject = { type: 'error', message: serverErrorMessage }
  }

  // return an ErrorMessage Object when the input is touched and a formik error is existing
  if (touched[key] && errors[key]) {
    errorObject = { type: 'error', message: errors[key] }
  }

  return errorObject
}

export default fillMessageObject
