import React from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, CountrySelect } from '@boxine/tonies-ui'
import useHandleForm from '../../hooks/useHandleForm'
import { useRegion } from '../../hooks/useRegion'

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: minmax(0, 100%);
  grid-gap: 1rem;
  margin: 0 auto;
`

const StyledSubmitButton = styled(Button)`
  justify-self: center;
  margin-bottom: 1rem;
`

const initialValues = {
  country: '',
}

const ChooseCountryForm = ({ actionUrl, className }) => {
  const { t } = useTranslation()
  const { region } = useRegion()
  const { onChange, formRef, handleSubmit, values } = useHandleForm(
    {
      country: Yup.string(),
    },
    { initialValues }
  )

  return (
    <StyledForm
      action={actionUrl}
      className={className}
      method="POST"
      noValidate
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <CountrySelect
        name="country"
        isRequired
        onChangeFunc={onChange}
        value={values.country}
        defaultValue={region}
      />
      <StyledSubmitButton data-testid="choose-country-submit" type="submit">
        {t('chooseCountryForm:submitButton')}
      </StyledSubmitButton>
    </StyledForm>
  )
}

ChooseCountryForm.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
}

ChooseCountryForm.defaultProps = {
  className: null,
}

export default ChooseCountryForm
