import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from '@boxine/tonies-ui'
import useVerifyEmailStatus from '../../hooks/useVerifyEmailStatus'
import FullScreenTheme from '../../themes/FullScreenTheme'
import SectionTitle from '../../components/SectionTitle'
import SupportLink from '../../components/SupportLink'
import AppContext from '../../contexts/AppContext'
import getClientAlias from '../../helper/getClientAlias'
import { BelloAccent, Strong } from '../../components/Typography'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

const EmailConfirmationFullwidth = ({
  backUrl,
  client,
  emailVerificationStatusUrl,
  page,
  userEmail,
  verifyUrl,
  isShopCheckout,
}) => {
  const { isMinimalVersion } = useContext(AppContext)
  const { isValidClient, clientAlias } = getClientAlias(client)
  const { redirectToNextRequiredStep } = useVerifyEmailStatus(
    emailVerificationStatusUrl,
    verifyUrl || backUrl
  )
  const { t } = useTranslation()

  redirectToNextRequiredStep()

  useDocumentTitle(t('emailChange:ChangeSentEmailPageTitle'))

  return (
    <FullScreenTheme
      client={client}
      imgStyle={{
        marginBottom: '2rem',
        maxWidth: '8rem',
      }}
      hasBackLink={!isMinimalVersion}
      translationNamespace="emailConfirmationFullwidth"
      backUrl={backUrl}
    >
      <SectionTitle
        headlineContent={
          <Trans i18nKey={`emailConfirmationFullwidth:${page}.headline`}>
            <BelloAccent>Fast</BelloAccent> geschafft...
          </Trans>
        }
        subheadlineContent={
          <Trans i18nKey={`emailConfirmationFullwidth:${page}.subheadline`}>
            <>
              Wir haben dir eine E-Mail an
              <Strong>{{ userEmail }}</Strong> geschickt.
            </>
          </Trans>
        }
      />

      <SupportLink />

      {isValidClient && (
        <Button
          variant="secondary"
          as="a"
          href={backUrl}
          data-testid="password-updated-link"
          styling="primary"
        >
          <Trans
            i18nKey={`emailConfirmationFullwidth:${
              isShopCheckout ? 'checkout' : clientAlias
            }Link`}
          >
            <span>
              Zu <Strong>my</Strong>tonies
            </span>
          </Trans>
        </Button>
      )}
    </FullScreenTheme>
  )
}

EmailConfirmationFullwidth.propTypes = {
  backUrl: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  emailVerificationStatusUrl: PropTypes.string.isRequired,
  isShopCheckout: PropTypes.bool.isRequired,
  page: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  verifyUrl: PropTypes.string.isRequired,
}

export default EmailConfirmationFullwidth
