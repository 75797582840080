import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import EmailChangeResendForm from '../../components/EmailChangeResendForm'
import SectionTitle from '../../components/SectionTitle'
import FullScreenTheme from '../../themes/FullScreenTheme'
import { BelloAccent } from '../../components/Typography'

const StyledForm = styled(EmailChangeResendForm)`
  margin-bottom: 2rem;
`

const InvalidLinkEmailChangeResend = ({ actionUrl, client }) => {
  return (
    <FullScreenTheme
      client={client}
      translationNamespace="invalidLinkEmailChangeResend"
    >
      <SectionTitle
        headlineContent={
          <Trans i18nKey="invalidLinkEmailChangeResend:headline">
            <BelloAccent>Uups,</BelloAccent> dein Bestätigungslink ist
            abgelaufen.
          </Trans>
        }
        subheadlineContent={
          <Trans i18nKey="invalidLinkEmailChangeResend:subheadline">
            Der Link in deiner E-Mail ist nur 24 Stunden gültig. Aber halb so
            wild: Wir senden dir gerne einen neuen Bestätigungslink für deine
            E-Mail-Adresse zu. Du bist nur noch einen Klick entfernt:
          </Trans>
        }
      />
      <StyledForm actionUrl={actionUrl} />
    </FullScreenTheme>
  )
}

InvalidLinkEmailChangeResend.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
}

export default InvalidLinkEmailChangeResend
