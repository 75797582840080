import { media, useEnvLink } from '@boxine/tonies-ui'
import PropTypes from 'prop-types'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import {
  ConditionalStageWarning,
  isDevOrStageEnvironment,
} from '../components/ConditionalStageWarning'
import SectionTitle from '../components/SectionTitle'
import {
  BelloAccent,
  Text as TextComponent,
  Strong,
} from '../components/Typography'
import getImagePath from '../helper/getImagePath'
import { useRegion } from '../hooks/useRegion'

const SITE_PADDING_HORIZONTAL_DESKTOP = '4rem'
const SITE_PADDING_HORIZONTAL_MOBILE = '1.25rem'
const SITE_PADDING_VERTICAL_DESKTOP = '1rem'
const SITE_PADDING_VERTICAL_MOBILE = '2rem'

const ConditionalStageWarningWrapper = styled.div`
  background-color: ${props => props.theme.GrayLightest};
  padding-top: 6rem;
  padding-bottom: 1rem;

  ${media.tablet`
    background-color: transparent;
    grid-column: login-start / teaser-end;
    grid-row: header-start / header-end;
    align-self: start;
    justify-self: center;
    z-index: 1; // Above gray background
  `};
`

const StyledConditionalStageWarning = styled(ConditionalStageWarning)`
  margin-left: 1rem;
  margin-right: 1rem;
`

const centerItem = css`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  height: calc(100% + 5rem);
  align-content: center;
  margin-top: -5rem;

  ${media.tablet`
    display: grid;
    grid-template-columns: [login-start] 1fr [login-end teaser-start] 1fr [teaser-end];
    grid-template-rows: [header-start] auto [header-end content-start] 1fr [content-end];
  `}
`

const StyledHeaderLeft = styled.div`
  ${centerItem};
  background-color: ${props => props.theme.GrayLightest};
  grid-column: login-start / login-end;
  grid-row: header-start / header-end;
  padding: 6rem ${SITE_PADDING_HORIZONTAL_MOBILE} 0;

  ${props =>
    props.hasStageWarning &&
    css`
      padding-top: 0;
    `}

  ${media.tablet`
    padding: 6rem ${SITE_PADDING_HORIZONTAL_DESKTOP} 0;
    
    ${props =>
      props.hasStageWarning &&
      css`
        padding-top: 14rem;
      `}
  `}
`

const StyledHeaderRight = styled.div`
  ${centerItem};
  align-self: flex-end;
  grid-column: teaser-start / teaser-end;
  grid-row: header-start / header-end;
  margin: 0 auto;
  max-width: 26rem;
  padding: ${SITE_PADDING_VERTICAL_MOBILE} ${SITE_PADDING_HORIZONTAL_MOBILE} 0;
  ${media.tablet`
    padding: 0 ${SITE_PADDING_HORIZONTAL_DESKTOP};
  `}
`

const ContentLeft = styled.div`
  ${centerItem};
  background-color: ${props => props.theme.GrayLightest};
  grid-column: login-start / login-end;
  grid-row: content-start / content-end;
  padding: 0 ${SITE_PADDING_HORIZONTAL_MOBILE} ${SITE_PADDING_VERTICAL_MOBILE}
    ${SITE_PADDING_VERTICAL_MOBILE};
  ${media.tablet`
    padding: 0 ${SITE_PADDING_HORIZONTAL_DESKTOP} ${SITE_PADDING_VERTICAL_DESKTOP};
  `}
`

const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30rem;
  margin: 0 auto;
`

const Teaser = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1.5rem;
`

const Text = styled(TextComponent)`
  text-align: center;
  margin: 0 0 1.5rem;
  max-width: 20rem;
`
const Red = styled.span`
  color: ${props => props.theme.BrandPrimary};
`

const StoreLink = styled.a`
  max-height: ${props => (props.isAppTeaser ? '3rem' : '6rem')};
  height: ${props => (props.isAppTeaser ? '15vw' : '20vw')};
  margin-left: auto;
`

const StyledImage = styled.img`
  height: 100%;
`

const FirstImage = styled(StoreLink)`
  margin-left: auto;
`

const IllustrationImg = styled.img`
  max-width: 90%;
  position: relative;
  right: -1rem;
`

const TeaserTheme = ({ headerLeft, contentLeft }) => {
  const { t } = useTranslation()

  const hasStageWarning = isDevOrStageEnvironment()

  /**
   * TODO: temporary fix to hide App-Teaser
   *
   * const { isAppClient } = useContext(AppContext)
   * const showAppTeaser = !isAppClient
   */
  const showAppTeaser = false
  const { region } = useRegion()
  const shopLink = useEnvLink(
    'shopLink',
    process.env.REACT_APP_ENVIRONMENT,
    region
  )

  const cloudLink = useEnvLink(
    'cloudLink',
    process.env.REACT_APP_ENVIRONMENT,
    region
  )

  return (
    <Wrapper>
      {hasStageWarning && (
        <ConditionalStageWarningWrapper>
          <StyledConditionalStageWarning />
        </ConditionalStageWarningWrapper>
      )}

      <StyledHeaderLeft hasStageWarning={hasStageWarning}>
        {headerLeft}
      </StyledHeaderLeft>
      <ContentLeft>{contentLeft}</ContentLeft>
      <StyledHeaderRight>
        <SectionTitle
          dataTestId="teaser-headline"
          headlineContent={
            <Trans
              i18nKey={
                showAppTeaser
                  ? 'themes:teaserHeadlineApp'
                  : 'themes:teaserHeadline'
              }
              components={{
                bello: <BelloAccent />,
                red: <Red />,
              }}
            />
          }
          headlineTag="h2"
        />
      </StyledHeaderRight>
      <DownloadWrapper>
        <Text>
          <Trans
            i18nKey={
              showAppTeaser
                ? 'themes:teaserSubheadlineApp'
                : 'themes:teaserSubheadline'
            }
            components={{
              bello: <BelloAccent />,
              bold: <Strong />,
              red: <Red />,
            }}
          />
        </Text>
        <Teaser data-testid="teaser">
          <FirstImage
            href={showAppTeaser ? t('themes:appStore.link') : cloudLink}
            target="_blank"
            rel="noopener"
            isAppTeaser={showAppTeaser}
          >
            <StyledImage
              alt={t(
                showAppTeaser ? 'themes:appStore.imgAlt' : 'themes:cloud.imgAlt'
              )}
              src={getImagePath(
                t(
                  showAppTeaser
                    ? 'themes:appStore.imgPath'
                    : 'themes:cloud.imgPath'
                )
              )}
            />
          </FirstImage>
          <StoreLink
            href={showAppTeaser ? t('themes:googlePlay.link') : shopLink}
            target="_blank"
            rel="noopener"
            isAppTeaser={showAppTeaser}
          >
            <StyledImage
              alt={t(
                showAppTeaser
                  ? 'themes:googlePlay.imgAlt'
                  : 'themes:shop.imgAlt'
              )}
              src={getImagePath(
                t(
                  showAppTeaser
                    ? 'themes:googlePlay.imgPath'
                    : 'themes:shop.imgPath'
                )
              )}
            />
          </StoreLink>
        </Teaser>

        {showAppTeaser && (
          <IllustrationImg
            alt={t(`themes:appIllustration.imgAlt`)}
            src={getImagePath(t(`themes:appIllustration.imgPath`))}
          />
        )}
      </DownloadWrapper>
    </Wrapper>
  )
}

TeaserTheme.propTypes = {
  headerLeft: PropTypes.node.isRequired,
  contentLeft: PropTypes.node.isRequired,
}

export default TeaserTheme
