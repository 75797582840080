import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@boxine/tonies-ui'

const StyledForm = styled.form`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  max-width: 17.5rem;
  width: 100%;
`

const StyledSubmitButton = styled(Button)`
  justify-self: center;
`

const EmailChangeResendForm = ({ actionUrl, className }) => {
  const { t } = useTranslation()

  return (
    <StyledForm
      action={actionUrl}
      className={className}
      method="POST"
      noValidate
    >
      <StyledSubmitButton data-testid="emailChangeResend-submit" type="submit">
        {t('emailChangeResendForm:submitButton')}
      </StyledSubmitButton>
    </StyledForm>
  )
}

EmailChangeResendForm.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
}

EmailChangeResendForm.defaultProps = {
  className: null,
}

export default EmailChangeResendForm
