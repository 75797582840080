import React from 'react'
import { PropTypes } from 'prop-types'
import { Button } from '@boxine/tonies-ui'
import { Trans, useTranslation } from 'react-i18next'
import SectionTitle from '../../components/SectionTitle'
import { BelloAccent, Strong } from '../../components/Typography'
import FullScreenTheme from '../../themes/FullScreenTheme'
import getClientAlias from '../../helper/getClientAlias'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

const PasswordUpdated = ({ backUrl, client }) => {
  const { t } = useTranslation()
  const { isValidClient, clientAlias } = getClientAlias(client)

  useDocumentTitle(t('passwordUpdated:PageTitle'))

  return (
    <FullScreenTheme
      imgStyle={{
        marginBottom: '2rem',
        maxWidth: '8rem',
      }}
      translationNamespace="passwordUpdated"
    >
      <SectionTitle
        headlineContent={
          <Trans i18nKey="passwordUpdated:headline">
            <BelloAccent>Erledigt:</BelloAccent> Dein Passwort wurde
            aktualisiert.
          </Trans>
        }
        subheadlineContent={t('passwordUpdated:subheadline')}
      />

      {isValidClient && (
        <Button
          variant="secondary"
          as="a"
          href={backUrl}
          data-testid="password-updated-link"
        >
          <Trans i18nKey={`accountCreated:${clientAlias}Link`}>
            <span>
              Zu <Strong>my</Strong>tonies
            </span>
          </Trans>
        </Button>
      )}
    </FullScreenTheme>
  )
}

PasswordUpdated.propTypes = {
  backUrl: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
}

export default PasswordUpdated
