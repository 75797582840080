import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@boxine/tonies-ui'
import { useTranslation, Trans } from 'react-i18next'
import getImagePath from '../../helper/getImagePath'
import { Text } from '../Typography'

const StyledBox = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 2rem;
  justify-items: center;
  max-width: 25rem;

  @media screen and (min-width: 580px) {
    grid-template-rows: 120px auto auto;
    max-width: auto;
  }
`

const StyledText = styled(Text)`
  font-size: 1.25rem;
  text-align: center;
`

const StyledImage = styled.img`
  max-width: 10rem;
`

const Strong = styled.b`
  font-weight: 700;
`

const TeaserBox = ({ id, link }) => {
  const { t } = useTranslation()

  return (
    <StyledBox data-testid={`teaser-box-${id}`}>
      <StyledImage
        alt={t(`chooseSite:${id}.imgAlt`)}
        src={getImagePath(t(`chooseSite:${id}.imgPath`))}
      />
      <StyledText>{t(`chooseSite:${id}.text`)}</StyledText>
      <Button
        variant="primary"
        as="a"
        data-testid={`teaser-box-link-${id}`}
        href={link}
      >
        <Trans i18nKey={`chooseSite:${id}.linkText`}>
          <span>
            Zu <Strong>my</Strong>Tonies
          </span>
        </Trans>
      </Button>
    </StyledBox>
  )
}

TeaserBox.propTypes = {
  id: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default TeaserBox
