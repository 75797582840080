import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: ${props => props.theme.GrayLightest};
  border: ${props => `1px solid ${props.theme.GrayLighter}`};
  padding: 1rem;
  width: 100%;
  margin-bottom: 2rem;
`

const BorderBox = ({ children, className }) => {
  return <Wrapper className={className}>{children}</Wrapper>
}

BorderBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

BorderBox.defaultProps = {
  className: null,
}

export default BorderBox
