import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import PasswordChangeForm from '../../components/PasswordChangeForm'
import SectionTitle from '../../components/SectionTitle'
import FullScreenTheme from '../../themes/FullScreenTheme'
import AppContext from '../../contexts/AppContext'
import { BelloAccent } from '../../components/Typography'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

const StyledForm = styled(PasswordChangeForm)`
  margin-bottom: 2rem;
`

const PasswordChange = ({
  actionUrl,
  backUrl,
  client,
  errorStatus,
  hiddenFormValueStateChecker,
  userEmail,
}) => {
  const { isMinimalVersion } = useContext(AppContext)
  const { t } = useTranslation()

  useDocumentTitle(t('passwordChange:PageTitle'))

  return (
    <FullScreenTheme
      backUrl={backUrl}
      client={client}
      hasBackLink={!isMinimalVersion}
      translationNamespace="passwordChange"
    >
      <SectionTitle
        headlineContent={
          <Trans i18nKey="passwordChange:headline">
            <>
              Passwort <BelloAccent>ändern?</BelloAccent>
            </>
          </Trans>
        }
        subheadlineContent={
          <Trans i18nKey="passwordChange:subheadline">
            Bitte trage hier dein altes und neues Passwort ein und klicke auf
            „Jetzt ändern“. Es gilt schon für deinen nächsten Login.
          </Trans>
        }
      />
      <StyledForm
        actionUrl={actionUrl}
        backUrl={backUrl}
        client={client}
        errorStatus={errorStatus}
        hiddenFormValueStateChecker={hiddenFormValueStateChecker}
        userEmail={userEmail}
      />
    </FullScreenTheme>
  )
}

PasswordChange.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  backUrl: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  errorStatus: PropTypes.string,
  hiddenFormValueStateChecker: PropTypes.string.isRequired,
  userEmail: PropTypes.string,
}

PasswordChange.defaultProps = {
  errorStatus: null,
  userEmail: null,
}

export default PasswordChange
