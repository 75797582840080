import {
  CountryCodes,
  GEOIP_API_ENDPOINT,
  GeoIpRegions,
  useGeoIp,
} from '@boxine/tonies-ui'
import React from 'react'

export type Regions = 'DACH' | 'UKI' | 'USA' | 'FRA' | 'EUR' | 'ROW'

interface RegionContextValue {
  region?: GeoIpRegions
  country?: CountryCodes
}

export const RegionContext = React.createContext<RegionContextValue>({
  region: undefined,
  country: undefined,
})

interface RegionProviderProps {
  children: React.ReactNode
}

export function RegionProvider({ children }: RegionProviderProps) {
  const { geoIpRegion, geoIpCountry } = useGeoIp({
    endpoint: GEOIP_API_ENDPOINT,
  })

  return (
    <RegionContext.Provider
      value={{ region: geoIpRegion, country: geoIpCountry }}
    >
      {children}
    </RegionContext.Provider>
  )
}
