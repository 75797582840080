import clientMapper from './clientMapper'

function getClientAlias(client) {
  const alias = clientMapper[client]
  return {
    isValidClient: alias !== undefined,
    clientAlias: alias || 'default',
  }
}

export default getClientAlias
