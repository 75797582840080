import React from 'react'
import styled from 'styled-components'
import { Button, variables } from '@boxine/tonies-ui'
import { useTranslation, Trans } from 'react-i18next'
import { Strong, Text } from '../Typography'
import { zendeskLinks } from '../../zendesk'

const { BrandPrimary } = variables

const StyledLink = styled(Button)`
  color: ${BrandPrimary};
  text-decoration: underline;
`

const StyledText = styled(Text)`
  display: grid;
  grid-gap: 0.6rem;
  justify-items: center;
  line-height: 1;
  margin: 0 0 2rem;
  text-align: center;
`

const SupportLink = () => {
  const { t } = useTranslation()

  return (
    <StyledText>
      <Trans i18nKey="supportLink:text">
        Du hast keine E-Mail bekommen?
        <Strong> Auch im Spam-Ordner ist sie nicht zu finden?</Strong>
      </Trans>
      <StyledLink
        variant="secondary"
        as="a"
        data-testid="support-link"
        href={zendeskLinks().supportLink}
      >
        {t('supportLink:linkText')}
      </StyledLink>
    </StyledText>
  )
}

export default SupportLink
