import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import PasswordResetForm from '../../components/PasswordResetForm'
import SectionTitle from '../../components/SectionTitle'
import FullScreenTheme from '../../themes/FullScreenTheme'
import { BelloAccent } from '../../components/Typography'

const StyledForm = styled(PasswordResetForm)`
  margin-bottom: 2rem;
`

const InvalidLinkPasswordReset = ({
  actionUrl,
  client,
  registerUrl,
  serverErrors,
  userEmail,
}) => {
  return (
    <FullScreenTheme
      client={client}
      translationNamespace="invalidLinkPasswordReset"
    >
      <SectionTitle
        headlineContent={
          <Trans i18nKey="invalidLinkPasswordReset:headline">
            <BelloAccent>Uups,</BelloAccent> du hast dein Passwort vergessen?
          </Trans>
        }
        subheadlineContent={
          <Trans i18nKey="invalidLinkPasswordReset:subheadline">
            Der Link in deiner E-Mail ist nur 24 Stunden gültig. Aber halb so
            wild: Bitte trage hier deine E-Mail-Adresse ein und klicke auf
            „Absenden“. Wir schicken dir dann direkt einen Link, mit dem du ein
            neues Passwort festlegen kannst.
          </Trans>
        }
      />
      <StyledForm
        actionUrl={actionUrl}
        client={client}
        registerUrl={registerUrl}
        serverErrors={serverErrors}
        userEmail={userEmail}
      />
    </FullScreenTheme>
  )
}

InvalidLinkPasswordReset.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  registerUrl: PropTypes.string.isRequired,
  serverErrors: PropTypes.object,
  userEmail: PropTypes.string,
}

InvalidLinkPasswordReset.defaultProps = {
  serverErrors: null,
  userEmail: null,
}

export default InvalidLinkPasswordReset
