import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Input, InputPassword, Button, variables } from '@boxine/tonies-ui'
import useHandleForm from '../../hooks/useHandleForm'
import fillMessageObject from '../../helper/fillMessageObject'
import useDisabledSubmitButton from '../../hooks/useDisabledSubmitButton'
import FormInfoText from '../FormInfoText'
import { emailRegex } from '../../helper/variables'

const { BrandPrimary } = variables

const StyledForm = styled.form`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  max-width: 17.5rem;
  width: 100%;
`

const StyledPasswordLink = styled(Button)`
  color: ${BrandPrimary};
  font-size: 0.75rem;
  justify-self: baseline;
  margin: 1rem 0 0.5rem;
`

const StyledLoginButton = styled(Button)`
  justify-self: center;
`

const StyledInput = styled(Input)`
  -webkit-hyphens: none;
`

const LoginForm = ({
  actionUrl,
  className,
  clientId,
  errorStatus,
  page,
  resetPasswordUrl,
  userEmail,
}) => {
  const { t } = useTranslation()
  const errorMessage = useMemo(
    () => errorStatus === 'login_failed' && t(`loginForm:errors.loginFailed`),
    [errorStatus, t]
  )

  const {
    errors,
    formRef,
    handleBlur,
    handleSubmit,
    onChange,
    submitted,
    touched,
    values,
  } = useHandleForm(
    {
      username: Yup.string()
        .matches(emailRegex, t('loginForm:errors.emailNotValid'))
        .required(t('loginForm:errors.emailRequired')),
      password: Yup.string().required(t('loginForm:errors.passwordRequired')),
    },
    {
      initialValues: {
        username: userEmail || '',
        password: '',
      },
    },
    errorMessage
  )
  const isSubmitButtonDisabled = useDisabledSubmitButton(errors) || submitted

  return (
    <StyledForm
      action={actionUrl}
      className={className}
      method="POST"
      noValidate
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <input type="hidden" name="grant_type" value="password" />
      <input type="hidden" name="client_id" value={clientId} />
      <input type="hidden" name="rememberMe" value="on" />
      {page === 'invalid-link' && (
        <input type="hidden" name="action_token_error" value="invalid-code" />
      )}
      <StyledInput
        dataTestId="username"
        autoComplete="username"
        isFullWidth
        isRequired
        label={t('loginForm:username')}
        message={fillMessageObject('username', errorMessage, touched, errors)}
        name="username"
        onBlur={handleBlur}
        onChange={onChange}
        placeholder={t('loginForm:username')}
        type="email"
        value={values.username}
      />
      <div>
        <InputPassword
          dataTestId="password"
          autoComplete="current-password"
          isFullWidth
          isRequired
          label={t('loginForm:password')}
          message={fillMessageObject('password', errorMessage, touched, errors)}
          name="password"
          onBlur={handleBlur}
          onChange={onChange}
          placeholder={t('loginForm:password')}
          value={values.password}
        />

        {page === 'invalid-link' && (
          <FormInfoText>{t('loginForm:passwordInfo')}</FormInfoText>
        )}

        <StyledPasswordLink
          variant="secondary"
          data-testid="password-reset"
          as="a"
          href={resetPasswordUrl}
        >
          {t('loginForm:passwordResetButton')}
        </StyledPasswordLink>
      </div>
      <StyledLoginButton
        data-testid="login-submit"
        disabled={isSubmitButtonDisabled}
        type="submit"
      >
        {t(
          page === 'invalid-link'
            ? 'loginForm:submitEmail'
            : 'loginForm:submitButton'
        )}
      </StyledLoginButton>
    </StyledForm>
  )
}

LoginForm.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  clientId: PropTypes.string.isRequired,
  errorStatus: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  resetPasswordUrl: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
}

LoginForm.defaultProps = {
  className: null,
}

export default LoginForm
