import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { Ul } from '@boxine/tonies-ui'
import { Trans, useTranslation } from 'react-i18next'
import DeleteAccountForm from '../../components/DeleteAccountForm'
import SectionTitle from '../../components/SectionTitle'
import FullScreenTheme from '../../themes/FullScreenTheme'
import BorderBox from '../../components/BorderBox'
import {
  BelloAccent,
  Strong,
  Text as TextComponent,
} from '../../components/Typography'
import AppContext from '../../contexts/AppContext'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

const StyledUL = styled(Ul)`
  font-family: ${props => props.theme.fonts.serif};
`

const Text = styled(TextComponent)`
  margin: 0 0 2rem;
  text-align: center;
`

const StyledForm = styled(DeleteAccountForm)`
  margin-bottom: 2rem;
`

const DeleteAccount = ({
  actionUrl,
  backUrl,
  client,
  errorStatus,
  hiddenFormValueStateChecker,
}) => {
  const { isMinimalVersion } = useContext(AppContext)
  const { t } = useTranslation()

  useDocumentTitle(t('deleteAccount:PageTitle'))

  return (
    <FullScreenTheme
      backUrl={backUrl}
      client={client}
      hasBackLink={!isMinimalVersion}
      translationNamespace="deleteAccount"
    >
      <SectionTitle
        dataTestId="delete-account-success-section-title"
        headlineContent={
          <Trans i18nKey="deleteAccount:headline">
            <>
              <BelloAccent>Sicher?</BelloAccent> Du möchtest dein Tonie-Konto
              wirklich löschen?
            </>
          </Trans>
        }
        subheadlineContent={
          <Trans i18nKey="deleteAccount:subheadline">
            <>
              <Strong>Bitte beachte:</Strong> Wenn du dein Tonie-Konto löschst,
              verlierst du <Strong>unwiderruflich</Strong> alle Daten aus{' '}
              <Strong>my</Strong>tonies und dem Onlineshop. Dazu gehören:
            </>
          </Trans>
        }
      />

      <BorderBox>
        <StyledUL>
          <li data-testid="delete-tonieboxes">
            {t('deleteAccount:tonieboxInfo')}
          </li>

          <li data-testid="delete-creative-tonies">
            {t('deleteAccount:creativeTonieInfo')}
          </li>

          <li data-testid="delete-content-tonies">
            {t('deleteAccount:contentTonieInfo')}
          </li>

          <li data-testid="delete-tunes">{t('deleteAccount:tunesInfo')}</li>

          <li data-testid="delete-household-and-membership">
            {t('deleteAccount:householdAndMembershipInfo')}
          </li>

          <li data-testid="delete-shop-data">{t('deleteAccount:shopInfo')}</li>
        </StyledUL>
      </BorderBox>

      <Trans i18nKey="deleteAccount:text">
        <Text>
          Bitte gib jetzt noch einmal dein Passwort ein, um zu bestätigen, dass
          du dein Tonie-Konto und alle oben aufgeführten Inhalte wirklich{' '}
          <Strong>unwiderruflich</Strong> löschen möchtest.
        </Text>
      </Trans>
      <StyledForm
        actionUrl={actionUrl}
        backUrl={backUrl}
        errorStatus={errorStatus}
        hiddenFormValueStateChecker={hiddenFormValueStateChecker}
      />
    </FullScreenTheme>
  )
}

DeleteAccount.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  backUrl: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  errorStatus: PropTypes.string,
  hiddenFormValueStateChecker: PropTypes.string.isRequired,
}

DeleteAccount.defaultProps = {
  errorStatus: null,
}

export default DeleteAccount
