import { Accent, Paragraph } from '@boxine/tonies-ui'
import styled from 'styled-components'

export const BelloAccent = styled(Accent)`
  color: ${props => props.theme.BrandPrimary};
`

export const Text = styled(Paragraph)`
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: ${props => props.theme.LineHeight};
`

export const Strong = styled.b`
  font-weight: 700;
`
