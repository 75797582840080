import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Input, Button } from '@boxine/tonies-ui'
import useHandleForm from '../../hooks/useHandleForm'
import fillMessageObject from '../../helper/fillMessageObject'
import useDisabledSubmitButton from '../../hooks/useDisabledSubmitButton'
import { emailRegex } from '../../helper/variables'

const StyledForm = styled.form`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  max-width: 17.5rem;
  width: 100%;
`

const StyledLoginButton = styled(Button)`
  justify-self: center;
`

const StyledInput = styled(Input)`
  -webkit-hyphens: none;
`

const EmailChangeForm = ({
  actionUrl,
  className,
  errorStatus,
  hiddenFormValueStateChecker,
  userEmail,
  userFirstName,
  userLastName,
}) => {
  const { t } = useTranslation()
  const errorMessage = useMemo(
    () =>
      errorStatus === 'email_update_failed' &&
      t('emailChangeForm:errors.emailUpdateFailed'),
    [errorStatus, t]
  )

  const {
    errors,
    formRef,
    handleBlur,
    handleSubmit,
    onChange,
    touched,
    values,
  } = useHandleForm(
    {
      email: Yup.string()
        .matches(emailRegex, t('emailChangeForm:errors.emailNotValid'))
        .required(t('emailChangeForm:errors.required')),
      emailOld: Yup.string()
        .matches(emailRegex, t('emailChangeForm:errors.emailNotValid'))
        .test(
          userEmail,
          t('emailChangeForm:errors.emailNotMatch'),
          value => userEmail === value
        )
        .required(t('emailChangeForm:errors.required')),
    },
    {
      initialValues: {
        email: '',
        emailOld: errorMessage ? userEmail : '',
        firstName: '',
        lastName: '',
      },
    },
    errorMessage
  )
  const isSubmitButtonDisabled = useDisabledSubmitButton(errors)

  return (
    <StyledForm
      action={actionUrl}
      className={className}
      method="POST"
      noValidate
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <input type="hidden" name="submitAction" value="Save" />
      <input type="hidden" name="firstName" value={userFirstName} />
      <input type="hidden" name="lastName" value={userLastName} />
      <input
        type="hidden"
        name="stateChecker"
        value={hiddenFormValueStateChecker}
      />
      <StyledInput
        dataTestId="email-old"
        isFullWidth
        hasHiddenLabel
        isRequired
        label={t('emailChangeForm:emailOld')}
        message={fillMessageObject('emailOld', null, touched, errors)}
        name="emailOld"
        onBlur={handleBlur}
        onChange={onChange}
        placeholder={t('emailChangeForm:emailOld')}
        type="email"
        value={values.emailOld}
      />
      <StyledInput
        dataTestId="email"
        isFullWidth
        hasHiddenLabel
        isRequired
        label={t('emailChangeForm:email')}
        message={fillMessageObject('email', errorMessage, touched, errors)}
        name="email"
        onBlur={handleBlur}
        onChange={onChange}
        placeholder={t('emailChangeForm:email')}
        type="email"
        value={values.email}
      />
      <StyledLoginButton
        data-testid="change-email-submit"
        disabled={isSubmitButtonDisabled}
        type="submit"
      >
        {t('emailChangeForm:submitButton')}
      </StyledLoginButton>
    </StyledForm>
  )
}

EmailChangeForm.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  client: PropTypes.string.isRequired,
  errorStatus: PropTypes.string.isRequired,
  hiddenFormValueStateChecker: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  userFirstName: PropTypes.string.isRequired,
  userLastName: PropTypes.string.isRequired,
}

EmailChangeForm.defaultProps = {
  className: null,
}

export default EmailChangeForm
