import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import CompleteProfileForm from '../../components/CompleteProfileForm'
import FullScreenTheme from '../../themes/FullScreenTheme'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

const StyledForm = styled(CompleteProfileForm)`
  margin-bottom: 2rem;
`

const CompleteProfile = ({ backUrl, actionUrl, userEmail }) => {
  const { t } = useTranslation()

  useDocumentTitle(t('completeProfile:PageTitle'))

  return (
    <FullScreenTheme translationNamespace="completeProfile">
      <StyledForm backUrl={backUrl} actionUrl={actionUrl} />
    </FullScreenTheme>
  )
}

CompleteProfile.propTypes = {
  backUrl: PropTypes.string.isRequired,
  actionUrl: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
}

export default CompleteProfile
