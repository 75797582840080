import { useTranslation } from 'react-i18next'

export function useSupportedLanguages() {
  const { i18n, t } = useTranslation()

  return [
    {
      isActive: i18n.language === 'de' || i18n.language === 'de-DE',
      languageCode: 'de',
      text: t('language-de'),
    },
    {
      isActive: i18n.language === 'en-US',
      languageCode: 'en-US',
      text: t('language-en-US'),
    },
    {
      isActive: i18n.language === 'en' || i18n.language === 'en-GB',
      languageCode: 'en',
      text: t('language-en'),
    },
    {
      isActive: i18n.language === 'fr' || i18n.language === 'fr-FR',
      languageCode: 'fr',
      text: t('language-fr'),
    },
  ]
}
