import React, { FunctionComponent } from 'react'
import { StageWarning } from '@boxine/tonies-ui'

export function isDevOrStageEnvironment() {
  const { hostname } = window.location

  return hostname.includes('dev') || hostname.includes('stage')
}

export const ConditionalStageWarning: FunctionComponent<{
  className: string
}> = ({ className }) => {
  if (isDevOrStageEnvironment()) {
    return <StageWarning className={className} />
  } else {
    return null
  }
}
