import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { OmniButton } from '@boxine/tonies-ui'
import { Trans } from 'react-i18next'
import getClientAlias from '../../helper/getClientAlias'

const StyledBackLink = styled(OmniButton)`
  align-self: baseline;
  justify-self: baseline;
`
const Strong = styled.b`
  font-weight: 700;
`

const BackLink = ({ client, text, ...props }) => {
  const { isValidClient, clientAlias } = getClientAlias(client)

  if (isValidClient) {
    return (
      <StyledBackLink data-testid="link-back" isBackLink {...props}>
        {text || (
          <Trans i18nKey={`backLink:${clientAlias}Link`}>
            <span>
              Zurück zu <Strong>my</Strong>tonies
            </span>
          </Trans>
        )}
      </StyledBackLink>
    )
  }
  return null
}

BackLink.propTypes = {
  client: PropTypes.string.isRequired,
  text: PropTypes.string,
}

BackLink.defaultProps = {
  text: null,
}

export default BackLink
