import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { useTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'
import {
  Input,
  InputPassword,
  Button,
  Checkbox,
  CountrySelect,
  useEnvLink,
  CTARow,
} from '@boxine/tonies-ui'
import useHandleForm from '../../hooks/useHandleForm'
import fillMessageObject from '../../helper/fillMessageObject'
import useDisabledSubmitButton from '../../hooks/useDisabledSubmitButton'
import FormInfoText from '../FormInfoText'
import { emailRegex } from '../../helper/variables'
import { useRegion } from '../../hooks/useRegion'

const StyledForm = styled.form`
  display: grid;
  grid-auto-columns: minmax(0, 100%);
  grid-gap: 1rem;
  margin-bottom: 1rem;
  max-width: 25rem;
  width: 100%;
`

const StyledFormRow = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
`

const StyledNameText = styled.p`
  margin-bottom: 0;
  font-size: 0.8125rem;
`

const StyledLegalNote = styled.div`
  font-size: 0.8125rem;
  font-weight: 300;
`

const StyledLink = styled(Button)`
  color: ${props => props.theme.colors['primary']};
  font-size: 0.75rem;
  padding: 0;
`

const ErrorMessageLink = styled.a`
  color: inherit;
`

const StyledCountrySelect = styled(CountrySelect)`
  background-color: transparent;

  select {
    background-color: white;
    z-index: auto;
  }
`

const StyledInput = styled(Input)`
  -webkit-hyphens: none;
  hyphens: none;
`

// TODO: add FormError (server error) handling
const RegisterForm = ({
  actionUrl,
  className,
  errorStatus,
  loginUrl,
  resetPasswordUrl,
  serverErrors,
  userEmail,
  userFirstName,
  userLastName,
}) => {
  const { t } = useTranslation()
  const { region, country } = useRegion()

  const legalsDomain = useEnvLink(
    'cloudLink',
    process.env.REACT_APP_ENVIRONMENT,
    region || 'ROW'
  )

  const errorMessage = useMemo(
    () =>
      errorStatus === 'register_failed' &&
      t(`registerForm:errors.registerFailed`),
    [errorStatus, t]
  )

  const formErrorTranslations = {
    email: (
      <Trans i18nKey="registerForm:serverErrors.email">
        <span>
          Wolltest du dich
          <ErrorMessageLink href={loginUrl}>einloggen?</ErrorMessageLink>
          <br />
          Oder hast du dein
          <ErrorMessageLink href={resetPasswordUrl}>
            Passwort vergessen?
          </ErrorMessageLink>
        </span>
      </Trans>
    ),
  }

  const findFormErrorMessage = key =>
    serverErrors[key] && formErrorTranslations[key]

  const {
    onChange,
    formRef,
    handleSubmit,
    handleBlur,
    values,
    touched,
    errors,
  } = useHandleForm(
    {
      email: Yup.string()
        .matches(emailRegex, t('registerForm:errors.emailNotValid'))
        .required(t('registerForm:errors.emailRequired')),
      password: Yup.string()
        .required(t('registerForm:errors.passwordRequired'))
        .min(8, t('registerForm:errors.passwordTooShort')),
      tos_and_pp_accepted: Yup.boolean().oneOf(
        [true],
        t('registerForm:errors.tos_and_pp_accepted')
      ),
      country: Yup.string(),
    },
    {
      initialValues: {
        firstName: userFirstName || '',
        lastName: userLastName || '',
        email: userEmail || '',
        password: '',
        tos_and_pp_accepted: false,
        subscribe_newsletter: false,
      },
    },
    serverErrors
  )
  const isSubmitButtonDisabled = useDisabledSubmitButton(errors)
  const isNewsletterChecked =
    values['country'] === 'us' ? true : values['subscribe_newsletter']
  const isNewsletterCheckboxVisible = values['country'] !== 'us'

  return (
    <StyledForm
      ref={formRef}
      className={className}
      onSubmit={handleSubmit}
      action={actionUrl}
      method="POST"
      noValidate
    >
      <div>
        <StyledNameText>{t('registerForm:nameText')}</StyledNameText>
        <StyledFormRow>
          <Input
            dataTestId="first-name"
            isFullWidth
            isLabelHidden
            label={t('registerForm:firstName')}
            message={fillMessageObject(
              'firstName',
              errorMessage,
              touched,
              errors
            )}
            name="firstName"
            onBlur={handleBlur}
            onChange={onChange}
            placeholder={t('registerForm:firstName')}
            type="text"
            value={values.firstName}
          />
          <Input
            dataTestId="last-name"
            isFullWidth
            isLabelHidden
            label={t('registerForm:lastName')}
            message={fillMessageObject(
              'lastName',
              errorMessage,
              touched,
              errors
            )}
            name="lastName"
            onBlur={handleBlur}
            onChange={onChange}
            placeholder={t('registerForm:lastName')}
            type="text"
            value={values.lastName}
          />
        </StyledFormRow>
      </div>
      <StyledInput
        dataTestId="email"
        autoComplete="username"
        isFullWidth
        hasHiddenLabel
        isRequired
        label={t('registerForm:email')}
        message={fillMessageObject(
          'email',
          findFormErrorMessage('email'),
          touched,
          errors
        )}
        name="email"
        onBlur={handleBlur}
        onChange={onChange}
        placeholder={t('registerForm:email')}
        type="email"
        value={values.email}
      />

      <div>
        <InputPassword
          dataTestId="password"
          autoComplete="new-password"
          isFullWidth
          isRequired
          label={t('registerForm:password')}
          message={fillMessageObject('password', null, touched, errors)}
          name="password"
          onBlur={handleBlur}
          onChange={onChange}
          placeholder={t('registerForm:password')}
          value={values.password}
        />
        <FormInfoText>{t('registerForm:passwordInfo')}</FormInfoText>
      </div>
      <input type="hidden" name="password-confirm" value={values.password} />

      <StyledCountrySelect
        name="country"
        isRequired
        hasTooltip
        onChangeFunc={onChange}
        value={values.country}
        defaultValue={country}
      />

      <Checkbox
        dataTestIdCheckbox="terms-of-service-and-privacy-policy"
        isChecked={values['tos_and_pp_accepted']}
        label={
          <Trans i18nKey="registerForm:termsOfServiceAndPrivacyPolicy">
            <span>
              Mit Klick auf &quot;Jetzt registrieren&quot; erkläre ich mich mit
              den
              <StyledLink
                variant="secondary"
                as="a"
                href={`${legalsDomain}/legals/terms-of-service`}
                target="_blank"
                rel="noopener"
              >
                Nutzungsbedingungen
              </StyledLink>
              von tonies einverstanden und, dass ich Kenntnis von den
              <StyledLink
                variant="secondary"
                as="a"
                href={`${legalsDomain}/legals/privacy-policy`}
                target="_blank"
                rel="noopener"
              >
                Datenschutzbestimmungen
              </StyledLink>
              habe.
            </span>
          </Trans>
        }
        name="tos_and_pp_accepted"
        onBlur={handleBlur}
        onChange={onChange}
        status={
          fillMessageObject(
            'tos_and_pp_accepted',
            findFormErrorMessage('tos_and_pp_accepted'),
            touched,
            errors
          )?.type
        }
        message={{
          text: fillMessageObject(
            'tos_and_pp_accepted',
            findFormErrorMessage('tos_and_pp_accepted'),
            touched,
            errors
          )?.message,
        }}
      />

      {isNewsletterCheckboxVisible && (
        <Checkbox
          dataTestIdCheckbox="newsletter"
          isChecked={isNewsletterChecked}
          label={t('registerForm:newsletter')}
          name="subscribe_newsletter"
          onBlur={handleBlur}
          onChange={onChange}
          isOptional
        />
      )}

      <StyledLegalNote>{t('registerForm:legalNote')}</StyledLegalNote>

      <div>
        <CTARow justifyContent="center">
          <Button
            data-testid="register-submit"
            type="submit"
            disabled={isSubmitButtonDisabled}
          >
            {t('registerForm:submitButton')}
          </Button>
        </CTARow>
      </div>
    </StyledForm>
  )
}

export default RegisterForm
