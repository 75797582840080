import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { InputPassword, Button } from '@boxine/tonies-ui'
import useHandleForm from '../../hooks/useHandleForm'
import fillMessageObject from '../../helper/fillMessageObject'
import useDisabledSubmitButton from '../../hooks/useDisabledSubmitButton'
import FormInfoText from '../FormInfoText'

const StyledForm = styled.form`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  max-width: 17.5rem;
  width: 100%;
`

const StyledLoginButton = styled(Button)`
  justify-self: center;
`

const PasswordChangeForm = ({
  actionUrl,
  className,
  errorStatus,
  hiddenFormValueStateChecker,
  userEmail,
}) => {
  const { t } = useTranslation()
  const errorMessage = useMemo(
    () =>
      errorStatus === 'password_update_failed' &&
      t('passwordChangeForm:errors.passwordUpdateFailed'),
    [errorStatus, t]
  )

  const {
    errors,
    formRef,
    handleBlur,
    handleSubmit,
    onChange,
    touched,
    values,
  } = useHandleForm(
    {
      password: Yup.string().required(t('passwordChangeForm:errors.required')),
      'password-new': Yup.string()
        .required(t('passwordChangeForm:errors.required'))
        .min(8, t('passwordChangeForm:errors.passwordTooShort')),
    },
    {
      initialValues: {
        password: '',
        'password-new': '',
      },
    },
    errorMessage
  )
  const isSubmitButtonDisabled = useDisabledSubmitButton(errors)

  return (
    <StyledForm
      action={actionUrl}
      className={className}
      method="POST"
      noValidate
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <input type="hidden" name="submitAction" value="Save" />
      <input type="hidden" name="username" value={userEmail} />
      <input
        type="hidden"
        name="stateChecker"
        value={hiddenFormValueStateChecker}
      />
      <input
        type="hidden"
        name="password-confirm"
        value={values['password-new']}
      />
      <InputPassword
        dataTestId="password"
        autoComplete="password"
        isFullWidth
        isRequired
        label={t('passwordChangeForm:password')}
        message={fillMessageObject('password', errorMessage, touched, errors)}
        name="password"
        onBlur={handleBlur}
        onChange={onChange}
        placeholder={t('passwordChangeForm:password')}
        value={values.password}
      />
      <div>
        <InputPassword
          dataTestId="password-new"
          autoComplete="password-new"
          isFullWidth
          isRequired
          label={t('passwordChangeForm:passwordNew')}
          message={fillMessageObject('password-new', null, touched, errors)}
          name="password-new"
          onBlur={handleBlur}
          onChange={onChange}
          placeholder={t('passwordChangeForm:passwordNew')}
          value={values['password-new']}
        />
        <FormInfoText>{t('passwordChangeForm:info')}</FormInfoText>
      </div>
      <StyledLoginButton
        data-testid="password-change-submit"
        disabled={isSubmitButtonDisabled}
        type="submit"
      >
        {t('passwordChangeForm:submitButton')}
      </StyledLoginButton>
    </StyledForm>
  )
}

PasswordChangeForm.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  client: PropTypes.string.isRequired,
  errorStatus: PropTypes.string.isRequired,
  hiddenFormValueStateChecker: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
}

PasswordChangeForm.defaultProps = {
  className: null,
}

export default PasswordChangeForm
