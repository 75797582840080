import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { variables, Button, theme, useMedia } from '@boxine/tonies-ui'
import { Trans, useTranslation } from 'react-i18next'
import LoginForm from '../../components/LoginForm'
import SectionTitle from '../../components/SectionTitle'
import TeaserTheme from '../../themes/TeaserTheme'
import BackLink from '../../components/BackLink'
import getImagePath from '../../helper/getImagePath'
import AppContext from '../../contexts/AppContext'
import useStartPollingLocalStorage from '../../hooks/useStartPollingLocalStorage'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { Text, BelloAccent } from '../../components/Typography'

const { BrandPrimary } = variables

const StyledText = styled(Text)`
  margin: 0.25rem;
`

const StyledRegistrationLink = styled(Button)`
  color: ${BrandPrimary};
  text-decoration: underline;
`

const StyledForm = styled(LoginForm)`
  align-items: baseline;
  margin: 0 auto 2rem;
`

const StyledImage = styled.img`
  max-width: 10rem;

  @media screen and (max-width: ${props => props.theme.screenTablet}px) {
    justify-self: center;
  }
`

const Login = ({
  actionUrl,
  backUrl,
  client,
  errorStatus,
  registerUrl,
  resetPasswordUrl,
  userEmail,
}) => {
  const { isMinimalVersion } = useContext(AppContext)
  const { t } = useTranslation()
  const isTablet = useMedia({ minWidth: theme.screenTablet })

  useStartPollingLocalStorage(backUrl)

  useDocumentTitle(t('login:PageTitle'))

  return (
    <TeaserTheme
      headerLeft={
        <>
          {!isMinimalVersion && <BackLink href={backUrl} client={client} />}
          {isTablet && (
            <StyledImage alt="" src={getImagePath(t('login:imgPath'))} />
          )}
          <SectionTitle
            headlineContent={
              <Trans i18nKey="login:headline">
                <BelloAccent>Willkommen</BelloAccent> zurück!
              </Trans>
            }
            subheadlineContent={t('login:subheadline')}
          />
        </>
      }
      contentLeft={
        <>
          <StyledForm
            actionUrl={actionUrl}
            clientId={client}
            errorStatus={errorStatus}
            resetPasswordUrl={resetPasswordUrl}
            userEmail={userEmail}
          />
          <StyledText>{t('login:registrationText')}</StyledText>
          <StyledRegistrationLink
            variant="secondary"
            as="a"
            data-testid="registration-link"
            styling="text"
            href={registerUrl}
          >
            {t('login:registrationButton')}
          </StyledRegistrationLink>
        </>
      }
    />
  )
}

Login.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  backUrl: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  errorStatus: PropTypes.string,
  registerUrl: PropTypes.string.isRequired,
  resetPasswordUrl: PropTypes.string.isRequired,
  userEmail: PropTypes.string,
}

Login.defaultProps = {
  errorStatus: null,
  userEmail: null,
}

export default Login
