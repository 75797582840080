import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import ChooseCountryForm from '../../components/ChooseCountryForm'
import SectionTitle from '../../components/SectionTitle'
import FullScreenTheme from '../../themes/FullScreenTheme'
import { BelloAccent, Strong } from '../../components/Typography'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

const StyledForm = styled(ChooseCountryForm)`
  margin-bottom: 2rem;
`

const ChooseCountry = ({ actionUrl }) => {
  const { t } = useTranslation()
  useDocumentTitle(t('chooseCountry:PageTitle'))

  return (
    <FullScreenTheme translationNamespace="chooseCountry">
      <SectionTitle
        headlineContent={
          <Trans
            i18nKey="chooseCountry:headline"
            components={{ bello: <BelloAccent /> }}
          />
        }
        subheadlineContent={
          <Trans
            i18nKey="chooseCountry:subheadline"
            components={{ strong: <Strong /> }}
          />
        }
      />
      <StyledForm actionUrl={actionUrl} />
    </FullScreenTheme>
  )
}

ChooseCountry.propTypes = {
  actionUrl: PropTypes.string.isRequired,
}

export default ChooseCountry
