import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import NewPasswordForm from '../../components/NewPasswordForm'
import SectionTitle from '../../components/SectionTitle'
import FullScreenTheme from '../../themes/FullScreenTheme'
import { BelloAccent } from '../../components/Typography'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

const StyledForm = styled(NewPasswordForm)`
  margin-bottom: 2rem;
`

const PasswordChangeAfterReset = ({ actionUrl, client, errorStatus }) => {
  const { t } = useTranslation()
  useDocumentTitle(t('passwordChangeAfterReset:PageTitle'))

  return (
    <FullScreenTheme
      client={client}
      translationNamespace="passwordChangeAfterReset"
    >
      <SectionTitle
        headlineContent={
          <Trans i18nKey="passwordChangeAfterReset:headline">
            <>
              Auf ein <BelloAccent>Neues:</BelloAccent> Bitte lege dein Passwort
              fest.
            </>
          </Trans>
        }
        subheadlineContent={
          <Trans i18nKey="passwordChangeAfterReset:subheadline">
            Bitte trage hier dein altes und neues Passwort ein und klicke auf
            „Jetzt ändern“. Es gilt schon für deinen nächsten Login.
          </Trans>
        }
      />
      <StyledForm actionUrl={actionUrl} errorStatus={errorStatus} />
    </FullScreenTheme>
  )
}

PasswordChangeAfterReset.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  errorStatus: PropTypes.string,
}

PasswordChangeAfterReset.defaultProps = {
  errorStatus: null,
}

export default PasswordChangeAfterReset
