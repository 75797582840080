import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { theme, useMedia } from '@boxine/tonies-ui'
import AppContext from '../../contexts/AppContext'
import useVerifyEmailStatus from '../../hooks/useVerifyEmailStatus'
import TeaserTheme from '../../themes/TeaserTheme'
import getImagePath from '../../helper/getImagePath'
import SectionTitle from '../../components/SectionTitle'
import SupportLink from '../../components/SupportLink'
import BackLink from '../../components/BackLink'
import {
  BelloAccent,
  Strong,
  Text as TextComponent,
} from '../../components/Typography'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

const StyledImage = styled.img`
  max-width: 10rem;
`

const Text = styled(TextComponent)`
  text-align: center;
`

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 2rem;
  justify-items: center;
  max-width: 32rem;
`

const EmailConfirmationWithTeaser = ({
  emailVerificationStatusUrl,
  hasEmailVerificationPolling,
  page,
  userEmail,
  verifyUrl,
  backUrl,
  client,
}) => {
  const { t } = useTranslation()
  const isTablet = useMedia({ minWidth: theme.screenTablet })
  const { redirectToNextRequiredStep } = useVerifyEmailStatus(
    emailVerificationStatusUrl,
    verifyUrl
  )
  const { isMinimalVersion } = useContext(AppContext)
  const hasBackLink = !isMinimalVersion

  hasEmailVerificationPolling && redirectToNextRequiredStep()

  useDocumentTitle(t('emailChange:EmailConfirmationPageTitle'))

  return (
    <TeaserTheme
      translationNamespace={page}
      headerLeft={
        <>
          {hasBackLink && <BackLink href={backUrl} client={client} />}
          {isTablet && (
            <StyledImage
              alt=""
              src={getImagePath(t(`emailConfirmationWithTeaser:imgPath`))}
            />
          )}
          <SectionTitle
            headlineContent={
              <Trans i18nKey={`emailConfirmationWithTeaser:${page}.headline`}>
                <>
                  <BelloAccent>Hoppla,</BelloAccent> deine E-Mail-Adresse ist
                  noch nicht bestätigt.
                </>
              </Trans>
            }
          />
        </>
      }
      contentLeft={
        <GridWrapper>
          <Trans i18nKey={`emailConfirmationWithTeaser:${page}.text`}>
            <Text>
              Wie es scheint hast du uns deine E-Mail-Adresse
              <Strong>{{ userEmail }}</Strong>
              noch nicht bestätigt. Das kannst du ganz einfach nachholen.
              <Strong>
                Wir haben dir eine neue E-Mail zur Bestätigung geschickt.
              </Strong>
              Bitte schau in deinem Postfach nach und klicke auf den
              Bestätigungslink. Gerne senden wir dir den Link auch erneut zu:
            </Text>
          </Trans>

          <SupportLink />
        </GridWrapper>
      }
    />
  )
}

EmailConfirmationWithTeaser.propTypes = {
  backUrl: PropTypes.string,
  client: PropTypes.string,
  emailVerificationStatusUrl: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  verifyUrl: PropTypes.string.isRequired,
  hasEmailVerificationPolling: PropTypes.bool,
}

EmailConfirmationWithTeaser.defaultProps = {
  backUrl: '',
  client: '',
  hasEmailVerificationPolling: false,
}

export default EmailConfirmationWithTeaser
