import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import SectionTitle from '../../components/SectionTitle'
import { BelloAccent } from '../../components/Typography'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import FullScreenTheme from '../../themes/FullScreenTheme'

const DeleteAccountSuccess = () => {
  const { t } = useTranslation()

  useDocumentTitle(t('deleteAccountSuccess:PageTitle'))

  return (
    <FullScreenTheme
      imgStyle={{
        marginBottom: '2rem',
        maxWidth: '8rem',
      }}
      translationNamespace="deleteAccountSuccess"
    >
      <SectionTitle
        headlineContent={
          <Trans i18nKey="deleteAccountSuccess:headline">
            <>
              Du hast dein Tonie-Konto erfolgreich
              <BelloAccent>gelöscht.</BelloAccent>
            </>
          </Trans>
        }
        dataTestId="delete-account-success-section-title"
        subheadlineContent={t('deleteAccountSuccess:subheadline')}
      />
    </FullScreenTheme>
  )
}

export default DeleteAccountSuccess
