import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledText = styled.p`
  color: ${props => props.theme.Gray};
  font-size: 0.7rem;
  line-height: 1.5;
  margin-top: 0.5rem;
`

const FormInfoText = ({ children }) => (
  <StyledText data-testid="form-info">{children}</StyledText>
)

FormInfoText.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FormInfoText
