import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import Shell from './Shell'

const environment = process.env.REACT_APP_ENVIRONMENT || 'unspecified'

// See snippet in frontend/public/index.html
const isOldBrowser = !Object.values || !Object.entries || ![].includes

// Initialize Sentry
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN, // DSN = Data Source Name (= Sentry Project ID)
    environment,
    release: `keycloak-frontend`,
    blacklistUrls: [
      /https:\/\/app.launchdarkly.com/,
      /https:\/\/events.launchdarkly.com/,
    ],
    ignoreErrors: [
      // TOC-1551: This error is caused by Google Translate mutating
      // the DOM. The way React works it expects to have full
      // ownership of the DOM without anybody else messing with it.
      // See the ticket TOC-1551 for an in-depth description of what's
      // happening.
      /a\[b\]\.target\.className\.indexOf\(ac\)/,
      /a\[b\]\.target\.className\.indexOf\(bc\)/,

      /NetworkError/i,
      /The request timed out/i,
      /Software caused connection abort/i,
      /The network connection was lost/i,
      /The internet connection appears to be offline/i,
      /Die Netzwerkverbindung wurde unterbrochen/i,
      /Es besteht anscheinend keine Verbindung zum Internet/i,
      /Die Software hat einen Verbindungsabbruch verursacht/i,
      /Zeitüberschreitung bei der Anforderung/i,
    ],
    beforeSend(event, hint) {
      if (isOldBrowser) {
        return null
      }

      return event
    },
  })
}

const root = document.getElementById('root')
const {
  dataset,
  dataset: {
    errorEmail,
    errorPassword,
    errorStatus,
    errorTosAndPpAccepted,
    errorUsername,
    errorTryAgainLater,
    invalidLink,
    exempt,
  },
} = root

let backUrl = dataset.backUrl
if ('URL' in window) {
  const params = new URL(
    dataset.backUrl || window.location.href,
    window.location.origin
  ).searchParams
  backUrl = params?.get('backUrl') || dataset.backUrl
}

ReactDOM.render(
  <Shell
    {...dataset}
    backUrl={backUrl}
    serverErrors={{
      email: errorEmail === 'true',
      username: errorUsername === 'true',
      tryAgainLater: errorTryAgainLater === 'true',
      password: errorPassword === 'true',
      tosAndPpAccepted: errorTosAndPpAccepted === 'true',
    }}
    isEmailVerificationRequired={errorStatus === 'verify_email'}
    isInvalidLink={invalidLink === 'invalid-code'}
    isShopCheckout={exempt === 'true'}
  />,
  root
)
