import { useRef, useCallback, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

/**
 *
 * @param {any} schema
 * @param {import('formik').FormikValues} formikOptions
 * @param {string | false} serversideErrors
 * @returns
 */
const useHandleForm = (schema, formikOptions, serversideErrors) => {
  const formRef = useRef()
  const timeout = useRef(false)
  const [submitted, setSubmitted] = useState(false)
  const Schema = Yup.object().shape(schema)

  /*
   * use the form element which be passed as fromRef to trigger the native HTML submit after validation from Fromik
   */
  const onSubmit = () => {
    if (formRef.current) {
      /**
       * set localStorage to detect a form submit in other tabs with an eventHandler in useStartPollingLocalStorage
       * set a math.random() value to fire the eventHandler in any case
       */
      window.localStorage.setItem('startPolling', Math.random())
      setSubmitted(true)
      formRef.current.submit()
    }
  }

  const formikProps = useFormik({
    ...formikOptions,
    onSubmit: onSubmit,
    validationSchema: Schema,
  })
  const { touched, handleChange, setTouched } = formikProps

  const onChange = useCallback(
    event => {
      // debounce to only show errors AFTER the user stopped typing
      clearTimeout(timeout.current)

      timeout.current = setTimeout(
        () => {
          setTouched({
            ...touched,
            [event.target.name]: true,
          })
        },
        // if fields are already marked invalid, any change 'touches' it immediately
        // otherwise we wait a while, to avoid showing errors to the user while they're typing
        serversideErrors ? 0 : 2500
      )

      handleChange(event)
    },
    [handleChange, serversideErrors, setTouched, touched]
  )

  return { submitted, onChange, formRef, ...formikProps }
}

export default useHandleForm
