import { PropTypes } from 'prop-types'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import AccountCreated from './pages/AccountCreated'
import ChooseCountry from './pages/ChooseCountry'
import ChooseSite from './pages/ChooseSite'
import CompleteProfile from './pages/CompleteProfile'
import DeleteAccount from './pages/DeleteAccount'
import DeleteAccountSuccess from './pages/DeleteAccountSuccess'
import EmailChange from './pages/EmailChange'
import EmailChangeSuccess from './pages/EmailChangeSuccess'
import EmailConfirmationFullwidth from './pages/EmailConfirmationFullwidth'
import EmailConfirmationWithTeaser from './pages/EmailConfirmationWithTeaser'
import ErrorPage from './pages/ErrorPage'
import InvalidLinkEmailChangeResend from './pages/InvalidLinkEmailChangeResend'
import InvalidLinkLogin from './pages/InvalidLinkLogin'
import InvalidLinkPasswordReset from './pages/InvalidLinkPasswordReset'
import Login from './pages/Login'
import PasswordChange from './pages/PasswordChange'
import PasswordChangeAfterReset from './pages/PasswordChangeAfterReset'
import PasswordPolicy from './pages/PasswordPolicy'
import PasswordReset from './pages/PasswordReset'
import PasswordResetSentMail from './pages/PasswordResetSentMail'
import PasswordUpdated from './pages/PasswordUpdated'
import Register from './pages/Register'
import SiteTheme from './themes/SiteTheme'

const App = ({
  actionUrl,
  backUrl,
  client,
  emailVerificationStatusUrl,
  errorCode,
  errorStatus,
  hiddenFormValueStateChecker,
  isEmailVerificationRequired,
  isInvalidLink,
  isShopCheckout,
  loginUrl,
  page,
  registerUrl,
  resetPasswordUrl,
  serverErrors,
  userEmail,
  userFirstName,
  userLastName,
  verifyUrl,
}) => {
  const renderStaticPageComponentSwitch = () => {
    switch (page) {
      case 'login':
        if (isEmailVerificationRequired) {
          return (
            <EmailConfirmationWithTeaser
              emailVerificationStatusUrl={emailVerificationStatusUrl}
              hasEmailVerificationPolling
              page={page}
              userEmail={userEmail}
              verifyUrl={verifyUrl}
              backUrl={backUrl}
              client={client}
            />
          )
        }

        if (isInvalidLink) {
          return (
            <InvalidLinkLogin
              actionUrl={actionUrl}
              backUrl={backUrl}
              client={client}
              errorStatus={errorStatus}
              userEmail={userEmail}
            />
          )
        }

        return (
          <Login
            actionUrl={actionUrl}
            backUrl={backUrl}
            client={client}
            errorStatus={errorStatus}
            registerUrl={registerUrl}
            resetPasswordUrl={resetPasswordUrl}
            userEmail={userEmail}
          />
        )
      case 'register':
        if (isEmailVerificationRequired) {
          return (
            <EmailConfirmationWithTeaser
              emailVerificationStatusUrl={emailVerificationStatusUrl}
              hasEmailVerificationPolling
              page={page}
              userEmail={userEmail}
              verifyUrl={verifyUrl}
              backUrl={backUrl}
              client={client}
            />
          )
        }
        return (
          <Register
            actionUrl={actionUrl}
            backUrl={backUrl}
            client={client}
            errorStatus={errorStatus}
            loginUrl={loginUrl}
            resetPasswordUrl={resetPasswordUrl}
            serverErrors={serverErrors}
            userEmail={userEmail}
          />
        )
      case 'password-policy':
        return (
          <PasswordPolicy
            actionUrl={actionUrl}
            backUrl={backUrl}
            errorStatus={errorStatus}
          />
        )
      case 'complete-profile':
        return (
          <CompleteProfile
            backUrl={backUrl}
            actionUrl={actionUrl}
            userEmail={userEmail}
          />
        )
      case 'choose-country':
        return <ChooseCountry actionUrl={actionUrl} />
      case 'account-created':
        return <AccountCreated actionUrl={actionUrl} client={client} />
      case 'password-change':
        return (
          <PasswordChange
            actionUrl={actionUrl}
            backUrl={backUrl}
            client={client}
            errorStatus={errorStatus}
            hiddenFormValueStateChecker={hiddenFormValueStateChecker}
            userEmail={userEmail}
          />
        )
      case 'password-updated':
        return <PasswordUpdated backUrl={backUrl} client={client} />
      case 'email-change':
        if (isInvalidLink) {
          return (
            <InvalidLinkEmailChangeResend
              client={client}
              actionUrl={actionUrl}
            />
          )
        }

        if (isEmailVerificationRequired) {
          return (
            <EmailConfirmationFullwidth
              backUrl={backUrl}
              client={client}
              emailVerificationStatusUrl={emailVerificationStatusUrl}
              hasEmailVerificationPolling
              isShopCheckout={isShopCheckout}
              page={page}
              userEmail={userEmail}
              verifyUrl={verifyUrl}
            />
          )
        }

        return (
          <EmailChange
            actionUrl={actionUrl}
            backUrl={backUrl}
            client={client}
            errorStatus={errorStatus}
            hiddenFormValueStateChecker={hiddenFormValueStateChecker}
            userEmail={userEmail}
            userFirstName={userFirstName}
            userLastName={userLastName}
          />
        )
      case 'email-change-success':
        return <EmailChangeSuccess actionUrl={actionUrl} client={client} />
      case 'password-reset':
        if (isInvalidLink) {
          return (
            <InvalidLinkPasswordReset
              actionUrl={actionUrl}
              client={client}
              registerUrl={registerUrl}
              serverErrors={serverErrors}
              userEmail={userEmail}
            />
          )
        }

        return (
          <PasswordReset
            actionUrl={actionUrl}
            backUrl={backUrl}
            client={client}
            registerUrl={registerUrl}
            serverErrors={serverErrors}
            userEmail={userEmail}
          />
        )
      case 'password-reset-sent-mail':
        return <PasswordResetSentMail backUrl={backUrl} userEmail={userEmail} />
      case 'password-change-after-reset':
        return (
          <PasswordChangeAfterReset
            actionUrl={actionUrl}
            backUrl={backUrl}
            client={client}
            errorStatus={errorStatus}
          />
        )
      case 'delete-account':
        return (
          <DeleteAccount
            actionUrl={actionUrl}
            backUrl={backUrl}
            client={client}
            errorStatus={errorStatus}
            hiddenFormValueStateChecker={hiddenFormValueStateChecker}
          />
        )
      case 'delete-account-success':
        return <DeleteAccountSuccess />
      default:
        return <ChooseSite />
    }
  }

  return page ? (
    page === 'error' ? (
      <ErrorPage errorCode={errorCode} backUrl={backUrl} />
    ) : (
      <SiteTheme>{renderStaticPageComponentSwitch()}</SiteTheme>
    )
  ) : (
    <Router>
      <SiteTheme showLanguageSwitch={false}>
        <Switch>
          <Route component={ChooseSite} />
        </Switch>
      </SiteTheme>
    </Router>
  )
}

App.propTypes = {
  actionUrl: PropTypes.string,
  backUrl: PropTypes.string,
  client: PropTypes.string,
  emailVerificationStatusUrl: PropTypes.string,
  errorCode: PropTypes.string,
  errorStatus: PropTypes.string,
  hiddenFormValueStateChecker: PropTypes.string,
  isEmailVerificationRequired: PropTypes.bool,
  isInvalidLink: PropTypes.bool,
  isShopCheckout: PropTypes.bool.isRequired,
  language: PropTypes.string,
  loginUrl: PropTypes.string,
  page: PropTypes.string,
  registerUrl: PropTypes.string,
  resetPasswordUrl: PropTypes.string,
  serverErrors: PropTypes.any,
  userEmail: PropTypes.string,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  verifyUrl: PropTypes.string,
}

App.defaultProps = {
  actionUrl: null,
  backUrl: null,
  client: 'meine-tonies',
  emailVerificationStatusUrl: null,
  errorCode: '404',
  errorStatus: null,
  hiddenFormValueStateChecker: null,
  isEmailVerificationRequired: false,
  isInvalidLink: false,
  language: null,
  loginUrl: null,
  page: null,
  registerUrl: null,
  resetPasswordUrl: null,
  serverErrors: {},
  userEmail: null,
  userFirstName: null,
  userLastName: null,
  verifyUrl: null,
}

export default App
