const clientMapper = {
  'my-tonies': 'cloud',
  'meine-tonies': 'cloud',
  'shop-de': 'shop',
  'shop-uk': 'shop',
  'shop-us': 'shop',
  'shop-de-frontend': 'shop',
  'shop-uk-frontend': 'shop',
  'tonie-studio': 'mobileApp',
}

export default clientMapper
