import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { InputPassword, Button } from '@boxine/tonies-ui'
import useHandleForm from '../../hooks/useHandleForm'
import fillMessageObject from '../../helper/fillMessageObject'
import useDisabledSubmitButton from '../../hooks/useDisabledSubmitButton'

const StyledForm = styled.form`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  max-width: 17.5rem;
  width: 100%;
`

const StyledLoginButton = styled(Button)`
  justify-self: center;
`

const DeleteAccountForm = ({
  actionUrl,
  className,
  errorStatus,
  hiddenFormValueStateChecker,
}) => {
  const { t } = useTranslation()
  const errorMessage = useMemo(
    () =>
      errorStatus === 'delete_account_failed' &&
      t('deleteAccountForm:errors.deleteAccountFailed'),
    [errorStatus, t]
  )

  const {
    errors,
    formRef,
    handleBlur,
    handleSubmit,
    onChange,
    touched,
    values,
  } = useHandleForm(
    {
      password: Yup.string().required(t('deleteAccountForm:errors.required')),
    },
    {
      initialValues: {
        password: '',
      },
    },
    errorMessage
  )
  const isSubmitButtonDisabled = useDisabledSubmitButton(errors)

  return (
    <StyledForm
      action={actionUrl}
      className={className}
      method="POST"
      noValidate
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <input
        type="hidden"
        name="stateChecker"
        value={hiddenFormValueStateChecker}
      />
      <InputPassword
        dataTestId="password"
        autoComplete="password"
        isFullWidth
        isRequired
        label={t('deleteAccountForm:password')}
        message={fillMessageObject('password', errorMessage, touched, errors)}
        name="password"
        onBlur={handleBlur}
        onChange={onChange}
        placeholder={t('deleteAccountForm:password')}
        value={values.password}
      />
      <StyledLoginButton
        data-testid="delete-account-submit"
        disabled={isSubmitButtonDisabled}
        type="submit"
      >
        {t('deleteAccountForm:submitButton')}
      </StyledLoginButton>
    </StyledForm>
  )
}

DeleteAccountForm.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  errorStatus: PropTypes.string.isRequired,
  hiddenFormValueStateChecker: PropTypes.string.isRequired,
}

DeleteAccountForm.defaultProps = {
  className: null,
}

export default DeleteAccountForm
