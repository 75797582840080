import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'
import { Button } from '@boxine/tonies-ui'
import { Trans, useTranslation } from 'react-i18next'
import SectionTitle from '../../components/SectionTitle'
import FullScreenTheme from '../../themes/FullScreenTheme'
import SupportLink from '../../components/SupportLink'
import AppContext from '../../contexts/AppContext'
import { BelloAccent, Strong } from '../../components/Typography'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

const PasswordResetSentMail = ({ backUrl, userEmail }) => {
  const { isMinimalVersion } = useContext(AppContext)
  const { t } = useTranslation()

  useDocumentTitle(t('passwordResetSentMail:PageTitle'))

  return (
    <FullScreenTheme
      backUrl={backUrl}
      backLinkText={t('passwordResetSentMail:backLink')}
      imgStyle={{
        marginBottom: '2rem',
        maxWidth: '8rem',
      }}
      hasBackLink={!isMinimalVersion}
      translationNamespace="passwordResetSentMail"
    >
      <SectionTitle
        headlineContent={
          <Trans i18nKey="passwordResetSentMail:headline">
            <BelloAccent>Fast</BelloAccent> geschafft: Bitte lege dein neues
            Passwort fest.
          </Trans>
        }
        subheadlineContent={
          <Trans i18nKey="passwordResetSentMail:subheadline">
            <>
              Wir haben dir eine E-Mail an <Strong>{{ userEmail }}</Strong>
              geschickt. Bitte schau in deinem Postfach nach und klicke auf den
              Link in der E-Mail, um dein neues Passwort festzulegen.
            </>
          </Trans>
        }
      />
      <SupportLink />
      <Button
        variant="secondary"
        as="a"
        href={backUrl}
        data-testid="password-reset-sent-mail-back-to-login"
      >
        {t('passwordResetSentMail:backLink')}
      </Button>
    </FullScreenTheme>
  )
}

PasswordResetSentMail.propTypes = {
  backUrl: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
}

export default PasswordResetSentMail
