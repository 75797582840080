import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import PasswordResetForm from '../../components/PasswordResetForm'
import SectionTitle from '../../components/SectionTitle'
import FullScreenTheme from '../../themes/FullScreenTheme'
import AppContext from '../../contexts/AppContext'
import { BelloAccent } from '../../components/Typography'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

const StyledForm = styled(PasswordResetForm)`
  margin-bottom: 2rem;
`

const PasswordReset = ({
  actionUrl,
  backUrl,
  client,
  registerUrl,
  serverErrors,
  userEmail,
}) => {
  const { isMinimalVersion } = useContext(AppContext)
  const { t } = useTranslation()
  useDocumentTitle(t('passwordReset:PageTitle'))

  return (
    <FullScreenTheme
      backUrl={backUrl}
      client={client}
      hasBackLink={!isMinimalVersion}
      translationNamespace="passwordReset"
      backLinkText={t('passwordReset:backLink')}
    >
      <SectionTitle
        headlineContent={
          <Trans i18nKey="passwordReset:headline">
            <BelloAccent>Uups,</BelloAccent> du hast dein Passwort vergessen?
          </Trans>
        }
        subheadlineContent={
          <Trans i18nKey="passwordReset:subheadline">
            Bitte trage hier dein altes und neues Passwort ein und klicke auf
            „Jetzt ändern“. Es gilt schon für deinen nächsten Login.
          </Trans>
        }
      />
      <StyledForm
        actionUrl={actionUrl}
        client={client}
        registerUrl={registerUrl}
        serverErrors={serverErrors}
        userEmail={userEmail}
      />
    </FullScreenTheme>
  )
}

PasswordReset.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  backUrl: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  serverErrors: PropTypes.object,
  userEmail: PropTypes.string,
  registerUrl: PropTypes.string.isRequired,
}

PasswordReset.defaultProps = {
  serverErrors: null,
  userEmail: null,
}

export default PasswordReset
