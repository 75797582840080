import React from 'react'
import { PropTypes } from 'prop-types'
import { Button } from '@boxine/tonies-ui'
import { Trans, useTranslation } from 'react-i18next'
import SectionTitle from '../../components/SectionTitle'
import FullScreenTheme from '../../themes/FullScreenTheme'
import getClientAlias from '../../helper/getClientAlias'
import { BelloAccent, Strong } from '../../components/Typography'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

const EmailChangeSuccess = ({ actionUrl, client }) => {
  const { t } = useTranslation()
  const { isValidClient, clientAlias } = getClientAlias(client)

  useDocumentTitle(t('emailChangeSuccess:PageTitle'))

  return (
    <FullScreenTheme
      imgStyle={{
        marginBottom: '2rem',
        maxWidth: '8rem',
      }}
      translationNamespace="emailChangeSuccess"
    >
      <SectionTitle
        headlineContent={
          <Trans i18nKey="emailChangeSuccess:headline">
            <BelloAccent>Erledigt:</BelloAccent> Deine E-Mail-Adresse wurde
            aktualisiert.
          </Trans>
        }
        subheadlineContent={t('emailChangeSuccess:subheadline')}
      />

      {isValidClient && (
        <Button
          data-testid="account-created-submit"
          onClick={() => (window.location = actionUrl)}
        >
          <Trans i18nKey={`emailChangeSuccess:${clientAlias}Link`}>
            <span>
              Zu <Strong>my</Strong>tonies
            </span>
          </Trans>
        </Button>
      )}
    </FullScreenTheme>
  )
}

EmailChangeSuccess.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
}

export default EmailChangeSuccess
