import PropTypes from 'prop-types'
import React from 'react'
import {
  theme,
  ToniesFontStyles,
  ToniesGlobalStyles,
  useMedia,
} from '@boxine/tonies-ui'
import { I18nextProvider } from 'react-i18next'
import { ThemeProvider } from 'styled-components'
import i18nConfig from './i18next.module'
import App from './App'
import AppContext from './contexts/AppContext'
import { RegionProvider } from './providers/RegionProvider'

const Shell = props => {
  const isAppClient = ['tonie-studio', 'tonie-studio-legacy'].includes(
    props.client
  )
  const isTablet = useMedia({ maxWidth: theme.screenTablet })

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider
        value={{
          isAppClient,
          isMinimalVersion: isAppClient && isTablet,
        }}
      >
        <I18nextProvider i18n={i18nConfig}>
          <RegionProvider>
            <ToniesGlobalStyles />
            <ToniesFontStyles />
            <App {...props} />
          </RegionProvider>
        </I18nextProvider>
      </AppContext.Provider>
    </ThemeProvider>
  )
}

Shell.propTypes = {
  client: PropTypes.string,
}

Shell.defaultProps = {
  client: 'meine-tonies',
}

export default Shell
