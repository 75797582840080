import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme, useMedia } from '@boxine/tonies-ui'
import { useTranslation } from 'react-i18next'
import { ConditionalStageWarning } from '../components/ConditionalStageWarning'
import getImagePath from '../helper/getImagePath'
import BackLink from '../components/BackLink'

export const SITE_PADDING_HORIZONTAL_DESKTOP = '2rem'
export const SITE_PADDING_HORIZONTAL_MOBILE = '1.25rem'
export const SITE_PADDING_VERTICAL_DESKTOP = '1rem'
export const SITE_PADDING_VERTICAL_MOBILE = '2rem'

const Wrapper = styled.div`
  display: grid;
  justify-items: center;
  padding: ${SITE_PADDING_VERTICAL_MOBILE} ${SITE_PADDING_HORIZONTAL_MOBILE}
    ${SITE_PADDING_VERTICAL_MOBILE};
  margin: 0 auto;

  @media screen and (min-width: ${props => props.theme.screenTablet}px) {
    padding: ${SITE_PADDING_VERTICAL_DESKTOP} ${SITE_PADDING_HORIZONTAL_DESKTOP};
    margin: 0;
  }
`

const Content = styled.div`
  display: grid;
  justify-items: center;
  max-width: 45rem;
  padding: 0 1.25rem 2rem;

  @media screen and (min-width: ${props => props.theme.screenTablet}px) {
    padding: 0 2rem;
  }
`

const StyledImage = styled.img`
  margin-bottom: 1.5rem;
  max-width: 10rem;

  @media screen and (max-width: ${props => props.theme.screenTablet}px) {
    justify-self: center;
  }
`

const FullScreenTheme = ({
  backUrl,
  children,
  className,
  client,
  hasBackLink,
  imgStyle,
  translationNamespace,
  backLinkText,
}) => {
  const { t } = useTranslation()
  const isTablet = useMedia({ minWidth: theme.screenTablet })

  return (
    <Wrapper className={className}>
      <ConditionalStageWarning />

      {hasBackLink && backUrl && (
        <BackLink href={backUrl} client={client} text={backLinkText} />
      )}
      {isTablet && (
        <StyledImage
          style={imgStyle}
          alt=""
          src={getImagePath(t(`${translationNamespace}:imgPath`))}
        />
      )}

      <Content>{children}</Content>
    </Wrapper>
  )
}

FullScreenTheme.propTypes = {
  backLinkText: PropTypes.string,
  backUrl: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  client: PropTypes.string,
  hasBackLink: PropTypes.bool,
  imgStyle: PropTypes.object,
  translationNamespace: PropTypes.string.isRequired,
}

FullScreenTheme.defaultProps = {
  backLinkText: '',
  backUrl: '',
  className: '',
  client: '',
  hasBackLink: false,
  imgStyle: {},
}

export default FullScreenTheme
